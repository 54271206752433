import { ArrowDownTrayIcon, CheckIcon, EyeIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { updateCountPerPage } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage as _itemCountPerPage } from 'config'
import { cloneDeep } from 'lodash'
import { Document, DocumentType } from 'pages/Pipeline'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  deleteDocument,
  filterPipeline,
  openS3Document,
  updateDocumentNotes,
  updateDocumentOrder,
  updateDocumentStatus,
} from 'services'
import { svgSearch } from 'stories/assets'
import { Button, Input, Input2, Pagination, TextArea, Toggle } from 'stories/components'
import { confirm, formatDate } from 'utils'
import { useTitle } from 'utils/pageTitle'
import { renderHeader } from 'utils/table'

export * from './ServicingPipelineDocument'

export const ServicingPipeline = () => {
  const [isLoading, setLoading] = useState(false)
  const [list, setList] = useState<Document[]>([])
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    entity: null,
    orderBy: 'order',
    orderDir: '1',
    type: DocumentType.Servicing,
  })
  const { itemCountPerPage = _itemCountPerPage } = useSelector((state: any) => state.auth)
  const [total, setTotal] = useState(0)
  const [action, setAction] = useState('')
  const [pageNum, setPageNum] = useState(0)
  const [_countPerPage, setCountPerPage] = useState(itemCountPerPage)
  const [filterQuery, setFilterQuery] = useState('')
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [notes, setNotes] = useState<Record<number, string>>({})
  const [orders, setOrders] = useState<Record<number, number>>({})
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useTitle(`Servicing`)

  useEffect(() => {
    if (action) return
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [pageNum, _countPerPage])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => action == '' && filterData(filters, 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const onChangeFilter = (key: string, value: any, needRefetch = true) => {
    if (!isGetUsersOnce) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (needRefetch) {
      filterData(newFilters, 0)
      setPageNum(0)
    }
  }

  const filterData = async (filters: any, _pageNum: number = -1) => {
    if (action) return
    if (_pageNum === -1) _pageNum = pageNum
    const filterData = {
      ...filters,
      entity: filters.entity,
      skip: pageNum * _countPerPage,
      count: _countPerPage,
    }
    // if (JSON.stringify(filterData) == lastFilterData) return

    setAction('getLoans')
    setLoading(true)
    // setLastFilterData(JSON.stringify(filterData))
    const { data, total } = await filterPipeline(filterData)
    setList(data)
    setTotal(total)
    setLoading(false)
    setAction('')

    const orders: Record<number, number> = {}
    data.map((doc: Document) => {
      orders[doc.id] = doc.order
    })
    setOrders(orders)

    const notes: Record<number, string> = {}
    data.map((doc: Document) => {
      notes[doc.id] = doc.notes
    })
    setNotes(notes)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onChangeCountPerPage = (count: number) => {
    dispatch(updateCountPerPage(count))
    setCountPerPage(count)
    setPageNum(0)
  }

  const onUpdateStatus = async (id: number, status: boolean, index: number) => {
    setLoading(true)
    await updateDocumentStatus(id, status)
    const newList = cloneDeep(list)
    newList[index].status = status
    setList(newList)
    setLoading(false)
  }

  const onSetNotes = (id: number, v: string) => {
    const newNotes = cloneDeep(notes)
    newNotes[id] = v
    setNotes(newNotes)
  }

  const onUpdateNotes = async (id: number, index: number) => {
    if (notes[id] == list[index].notes) return

    setLoading(true)
    await updateDocumentNotes(id, notes[id])
    filterData(filters)
  }

  const onSetOrder = (id: number, v: string) => {
    const newOrders = cloneDeep(orders)
    newOrders[id] = Number(v)
    setOrders(newOrders)
  }

  const onUpdateOrder = async (id: number) => {
    setLoading(true)
    await updateDocumentOrder(id, orders[id])
    filterData(filters)
  }

  const onNewPdf = () => {
    navigate('/servicingPipeline/item')
  }

  const onSort = (key: string, sortOrder: number) => {
    if (sortOrder == 0) sortOrder = -1
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)
    filterData(newFilters)
  }

  const onDelete = async (item: Document) => {
    const result = await confirm('Do you want to remove this template?')
    if (!result) return

    setLoading(true)
    await deleteDocument(item.id)
    filterData(filters)
    setLoading(false)
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="flex flex-wrap items-center gap-2 mb-3">
          <h1 className="text-2xl font-variation-settings-600">
            <span>Servicing</span>
          </h1>
          <div className="flex-1" />
          <Button onClick={onNewPdf}>New Doc</Button>
        </div>
        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-3">
          <Input2
            type="search"
            title="Search ..."
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
        </div>

        <div className="table-container relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-2">No</th>
                {renderHeader({
                  title: 'Name',
                  key: 'name',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'name' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Notes',
                  key: 'notes',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'notes' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Order',
                  key: 'order',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'order' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Updated At',
                  key: 'updatedAt',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'updatedAt' ? parseInt(filters.orderDir) : 0,
                  className: 'w-48',
                })}
                <th className="px-2 w-32">Actions</th>
                <th className="px-2 w-32">Status</th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {list.map((item: Document, index: number) => {
                return (
                  <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      <span className="flex-col flex">{index + 1 + pageNum * _countPerPage}</span>
                    </td>
                    <td className="px-2 font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      <span
                        onClick={() => navigate('/servicingPipeline/item', { state: { doc: item } })}
                      >{`${item.name}`}</span>
                    </td>
                    <td className="px-2 py-2">
                      <div className="">
                        <TextArea
                          value={notes[item.id]}
                          rows={2}
                          onChange={(value) => onSetNotes(item.id, value)}
                          onBlur={() => onUpdateNotes(item.id, index)}
                          className=""
                        />
                      </div>
                    </td>
                    <td className="px-2 py-2 w-24">
                      <div className="flex items-center gap-2">
                        <Input
                          type="number"
                          value={`${orders[item.id]}`}
                          onChange={(v) => onSetOrder(item.id, v)}
                          className="w-16"
                        />
                        {orders[item.id] != item.order && (
                          <span
                            className="inline-block text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onUpdateOrder(item.id)}
                          >
                            <CheckIcon className="w-4 h-4" />
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="px-2 py-2">{`${formatDate(item.updatedAt)}`}</td>

                    <td className="px-2 py-2">
                      <span className="flex gap-1">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => openS3Document(item.fileKey, true)}
                        >
                          <EyeIcon className="w-4 h-4" />
                        </span>

                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => openS3Document(item.fileKey)}
                        >
                          <ArrowDownTrayIcon className="w-4 h-4" />
                        </span>

                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => {
                            navigate('/servicingPipeline/item', { state: { doc: item } })
                          }}
                        >
                          <PencilSquareIcon className="w-4 h-4" />
                        </span>

                        <span className="text-red-700 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item)}>
                          <TrashIcon className="w-4 h-4" />
                        </span>
                      </span>
                    </td>
                    <td className="px-2 py-2 scale-75">
                      <Toggle
                        id={`status-${item.id}`}
                        value={item.status}
                        onChange={(v) => onUpdateStatus(item.id, v, index)}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end items-center mt-3">
          <div className="flex-1" />
          <Pagination
            totalCount={total}
            itemCountPerPage={_countPerPage}
            onNavigate={onPageNavigate}
            onChangeCountPerPage={onChangeCountPerPage}
            pageNum={pageNum}
          />
        </div>
      </div>
    </div>
  )
}
