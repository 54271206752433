import { ArrowLeftIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import type { InputType, ModalProps } from 'config'
import { states } from 'config/states.constants'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { createConstructionDeedOfTrust, getConstructionDeedOfTrust, updateConstructionDeedOfTrust } from 'services'
import { Button } from 'stories/components'
import { getUnknownPlaceholders, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { ConstructionDeed, ConstructionDeedOfTrustCategory } from './type'

const defaultInputs = (): Record<string, InputType> => {
  return {
    // title: {
    //   title: 'Title',
    //   inputType: 'text',
    //   error: '',
    //   required: true,
    // },
    value: {
      title: 'Placeholder',
      inputType: 'text',
      error: '',
      required: true,
    },
    // label: {
    //   title: 'Field Label',
    //   inputType: 'text',
    //   error: '',
    //   required: true,
    // },
    defaultValue: {
      title: 'Default Value',
      inputType: 'textarea',
      error: '',
      rows: 4,
      required: true,
    },
  }
}

const defaultStateInputs = (): Record<string, InputType> => {
  return {
    state: {
      title: 'State',
      inputType: 'Select',
      options: states,
      required: true,
      hasDefaultOption: true,
    },
    value: {
      title: 'Value',
      inputType: 'textarea',
      error: '',
      rows: 4,
      required: true,
    },
  }
}

export const NewAddPage = (
  props: ModalProps & {
    category: ConstructionDeedOfTrustCategory
    type: string
    item: ConstructionDeed | null
    placeholders: string[]
  },
) => {
  const [isLoading, setLoading] = useState(true)
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())
  const [stateInputs, setStateInputs] = useState<Record<string, InputType>[]>([])

  useEffect(() => {
    if (props.item) {
      const newInputs = cloneDeep(inputs)
      for (let key in newInputs) newInputs[key].value = (props.item as any)[key]
      setInputs(newInputs)
      fetchStates(newInputs)
    } else setLoading(false)
  }, [])

  const fetchStates = async (oldInputs: Record<string, InputType>) => {
    if (!props.item) return

    const item: ConstructionDeed = await getConstructionDeedOfTrust(props.type, props.item.id)
    const { defaultValue, stateValues } = item

    const newInputs = cloneDeep(oldInputs)
    newInputs['defaultValue'].value = defaultValue
    setInputs(newInputs)

    const newStateInputs = cloneDeep(stateInputs)
    Object.keys(stateValues).forEach((key) => {
      const newInputs = defaultStateInputs()
      newInputs['state'].value = key
      newInputs['value'].value = stateValues[key]
      newStateInputs.push(newInputs)
    })
    setStateInputs(newStateInputs)
    setLoading(false)
  }

  const onChangeInputs = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onNewState = () => {
    const newInputs = cloneDeep(stateInputs)
    newInputs.push(defaultStateInputs())
    setStateInputs(newInputs)
  }

  const onChangeStateInputs = (index: number, key: string, value: string) => {
    console.log(index, key, value)
    const newInputs = cloneDeep(stateInputs)
    newInputs[index][key].value = value
    newInputs[index][key].error = ''
    setStateInputs(newInputs)
  }

  const onDeleteState = (index: number) => {
    const newInputs = cloneDeep(stateInputs)
    newInputs.splice(index, 1)
    setStateInputs(newInputs)
  }

  const onOk = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const inputData: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      if (!error && key == 'defaultValue') {
        const unknowns = getUnknownPlaceholders(value, props.placeholders)
        if (unknowns.length) error = `Includes unknown placeholders - ${unknowns.join(',')}`
      }
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (value !== undefined) inputData[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    const newStateStats = cloneDeep(stateInputs)
    const statesValueData: Record<string, string> = {}
    for (const stateInput of newStateStats) {
      const data: Record<string, any> = {}
      for (const key in stateInput) {
        const { value } = stateInput[key]
        let error = InputValidate(stateInput[key])
        if (!error && key == 'value') {
          const unknowns = getUnknownPlaceholders(value, props.placeholders)
          if (unknowns.length) error = `Includes unknown placeholders - ${unknowns.join(',')}`
        }

        stateInput[key].error = error
        if (error.length > 0) hasError = true

        if (value !== undefined) data[key] = value
      }
      if (data['state'] && statesValueData[data['state']] != undefined) {
        stateInput['state'].error = 'This state is duplicated.'
        hasError = true
      }
      statesValueData[data['state']] = data['value']
    }
    if (hasError) {
      setStateInputs(newStateStats)
      return
    }

    inputData.stateValues = statesValueData
    setLoading(true)
    inputData.category = props.category

    try {
      let newValues
      if (!props.item) newValues = await createConstructionDeedOfTrust(props.type, inputData)
      else newValues = await updateConstructionDeedOfTrust(props.type, props.item.id, inputData)

      props.onSubmit && props.onSubmit(newValues)
    } catch (e) {}
    setLoading(false)
  }

  return (
    <div className={`${props.type.replace(/ /g, '-')}-Modal-Container`}>
      <div className="flex gap-2 items-center mb-4">
        <span className="flex w-8 h-8">
          <span className="flex items-center gap-2 p-1 hover-shadow1 cursor-pointer" onClick={() => props.onClose()}>
            <ArrowLeftIcon className="w-6 h-6" />
          </span>
        </span>

        <h1 className="text-2xl font-variation-settings-600 flex items-center">
          {!props.item ? `Add new ${props.type}` : `Update ${props.type}`}
          {isLoading && <Loading />}
        </h1>
      </div>

      <div className={`text-gray-600 text-md`}>
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div key={index} className="input mb-3">
              <RenderInput input={input} Key={key} onChange={onChangeInputs} />
            </div>
          )
        })}

        <div className="w-full h-0.5 bg-gray-400 mb-4" />

        {stateInputs.map((inputs, index) => {
          return (
            <div key={`${inputs.length}-${index}`} className="border-b mb-2 pb-2">
              {Object.keys(inputs).map((key) => {
                const input = inputs[key]
                return (
                  <div key={`states-${index}-${key}`} className="input mb-3">
                    <RenderInput
                      input={input}
                      Key={key}
                      onChange={(key: string, value: string) => onChangeStateInputs(index, key, value)}
                    />
                  </div>
                )
              })}

              <span className="ml-auto flex mt-2 w-20 h-6">
                <span
                  className="flex items-center gap-2 text-red-700 p-1 hover-shadow1 cursor-pointer"
                  onClick={() => onDeleteState(index)}
                >
                  <TrashIcon className="w-4 h-4"></TrashIcon> Delete
                </span>
              </span>
            </div>
          )
        })}

        <div className="flex items-center gap-2 justify-between">
          <span
            className="flex items-center gap-1 text-shade-blue px-3 py-2 rounded hover-shadow1 cursor-pointer"
            onClick={onNewState}
          >
            <PlusIcon className="w-4 h-4"></PlusIcon> Add New State
          </span>
          <Button onClick={onOk}>Save</Button>
        </div>
      </div>
    </div>
  )
}
