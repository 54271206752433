import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { deletePrepaymentType, getPrepaymentTypes } from 'services'
import { svgSearch } from 'stories/assets'
import svgLoading from 'stories/assets/loading.svg'
import { Button, Input } from 'stories/components'
import { confirm, formatDate } from 'utils'

import { NewDialog } from './NewDialog'

export interface PrepaymentType {
  id: number
  value: string
  period: number
  text: string
  createdAt: string
}

export const PrepaymentTypes = () => {
  const [isLoading, setLoading] = useState(false)
  const [isShowAdd, setShowAdd] = useState(false)
  const [query, setQuery] = useState('')
  const [values, setValues] = useState<PrepaymentType[]>([])
  const [selectedItem, setSelectedItem] = useState<PrepaymentType | null>(null)

  useEffect(() => {
    setLoading(true)
    getPrepaymentTypes().then((values: PrepaymentType[]) => {
      setValues(values)
      setLoading(false)
    })
  }, [])

  const onAdd = () => {
    setShowAdd(true)
    setSelectedItem(null)
  }

  const onUpdate = (item: PrepaymentType) => {
    setShowAdd(true)
    setSelectedItem(item)
  }

  const onItemSubmit = (item: PrepaymentType) => {
    const newValues = cloneDeep(values)
    if (!selectedItem) newValues.push(item)
    else {
      const index = values.findIndex((v) => v.id == item.id)
      newValues[index] = item
    }
    setValues(newValues)
    setShowAdd(false)
  }

  const onDelete = async (item: PrepaymentType) => {
    if (item.id) {
      const result = await confirm('Do you want to remove this data?')
      if (!result) return
    }

    const index = values.findIndex((v) => v.createdAt === item.createdAt)
    if (index === -1) return

    setLoading(true)
    if (item.id) await deletePrepaymentType(item.id)

    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
    setLoading(false)
  }

  return (
    <div className="Prepayment-Type-Container">
      <div className="flex flex-wrap justify-between">
        <h1 className="text-2xl font-variation-settings-600 flex items-center pb-5">
          Prepayment Types
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h1>
        <Button onClick={onAdd}>+ Add</Button>
      </div>

      <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
        <Input
          type="search"
          title="Search ..."
          hasIcon
          icon={svgSearch}
          value={query}
          onChange={(value) => setQuery(value)}
        />
      </div>

      <div className="relative overflow-auto shadow-md sm:rounded-lg">
        <LayoutLoading show={isLoading} />
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-3 w-[40px]">
                No
              </th>
              <th scope="col" className="px-2 py-3">
                Prepayment
              </th>
              <th scope="col" className="px-2 py-3">
                Months
              </th>
              <th scope="col" className="px-2 py-3">
                Verbiage
              </th>
              <th scope="col" className="px-2 py-3 w-[100px]">
                Created At
              </th>
              <th scope="col" className="px-2 py-3 w-[70px]">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-[14px] text-gray-900">
            {values
              .filter(
                (v) =>
                  v.value.toLowerCase().includes(query.toLowerCase()) ||
                  `${v.period}`.includes(query.toLowerCase()) ||
                  v.text.toLowerCase().includes(query.toLowerCase()),
              )
              .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
              .map((value, index) => {
                return (
                  <tr key={`${value.createdAt}-${index}`} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">{index + 1}</td>

                    <td className="px-2 py-2">{value.value}</td>

                    <td className="px-2 py-2">{value.period}</td>

                    <td className="px-2 py-2">
                      <p>{value.text}</p>
                    </td>

                    <td className="px-2 py-2">{formatDate(value.createdAt)}</td>

                    <td className="px-2 py-2">
                      <span className="flex">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => onUpdate(value)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>

                        <span className="text-red-700 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(value)}>
                          <TrashIcon className="w-4 h-4"></TrashIcon>
                        </span>
                      </span>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        {isShowAdd && <NewDialog item={selectedItem} onSubmit={onItemSubmit} onClose={() => setShowAdd(false)} />}
      </div>
    </div>
  )
}
