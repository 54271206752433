import type { InputType, ModalProps } from 'config'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { createPlaceholder } from 'services'
import { Modal } from 'stories/components'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const NewPlaceholderDialog = (props: ModalProps) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({
    name: {
      title: 'Placeholder Name',
      inputType: 'text',
      error: '',
      required: true,
    },
    description: {
      title: 'Description',
      inputType: 'textarea',
      error: '',
      required: true,
    },
  })

  const onChangeInputs = (key: string, value: string) => {
    if (key === 'name') value = value.replace(/[^\w'-]/g, '')

    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onOk = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const inputData: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (value !== undefined) inputData[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)

    try {
      const newValues = await createPlaceholder(inputData.name, inputData.description)
      props.onSubmit && props.onSubmit(newValues)
    } catch (e) {}
    setLoading(false)
  }

  return (
    <Modal
      isOpen
      title="Add new placeholder"
      titleOkay="Ok"
      onOk={onOk}
      onClose={() => props.onClose()}
      loading={isLoading}
    >
      <div className={`text-gray-600 text-md w-96`}>
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div key={index} className="input mb-3">
              <RenderInput input={input} Key={key} onChange={onChangeInputs} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
