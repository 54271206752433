import { ArrowDownTrayIcon, EyeIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { Document, DocumentType } from 'pages/Pipeline'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRelatedDocuments, openS3Document } from 'services'

import type { Placeholder } from '.'

export const PlaceholderDetails = ({ data, onClose }: { data: Placeholder; onClose: Function }) => {
  const [documents, setDocuments] = useState<Document[]>([])
  const [isLoading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    getRelatedDocuments(data.value)
      .then((documents) => setDocuments(documents))
      .finally(() => setLoading(false))
  }, [data.value])

  return (
    <div className="p-2 relative w-full">
      <div className="flex justify-between py-2">
        <p className="text-md">Documents</p>
        <p className={`hover:underline cursor-pointer text-red-600`} onClick={() => onClose()}>
          Close
        </p>
      </div>
      <div className="table-container overflow-x-auto shadow-md sm:rounded-lg z-10">
        {isLoading && <LayoutLoading show />}
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="p-2">No</th>
              <th className="p-2">Name</th>
              <th className="px-2">Actions</th>
            </tr>
          </thead>
          <tbody className="text-[14px] text-gray-900">
            {documents.map((item: Document, index: number) => (
              <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                <td className="pl-3 py-1">
                  <span className="flex-col flex">{index + 1}</span>
                </td>
                <td className="px-2 pl-3 py-1">{item.name}</td>
                <td className="px-2 py-1">
                  <span className="flex gap-1">
                    <span
                      className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                      onClick={() => {
                        if (item.type == DocumentType.Original) navigate(`/pipeline/${item.id}`)
                        else if (item.type == DocumentType.Postclosing)
                          navigate(`/postclosingPipeline/item`, { state: { doc: item } })
                        else if (item.type == DocumentType.Servicing)
                          navigate(`/servicingPipeline/item`, { state: { doc: item } })
                      }}
                    >
                      <PencilSquareIcon className="w-4 h-4" />
                    </span>
                    <span
                      className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                      onClick={() => openS3Document(item.fileKey, true)}
                    >
                      <EyeIcon className="w-4 h-4" />
                    </span>
                    <span
                      className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                      onClick={() => openS3Document(item.fileKey, false)}
                    >
                      <ArrowDownTrayIcon className="w-4 h-4" />
                    </span>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
