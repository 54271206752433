import { API_CONSTRUCTION_DEED, API_CONSTRUCTION_DEED_ITEM } from 'config'
import Api from 'services/api'

export const listConstructionDeedOfTrust = (category: string, type: string) => {
  return Api.get(API_CONSTRUCTION_DEED, { category }, { type })
}

export const getConstructionDeedOfTrust = (type: string, id: number) => {
  return Api.get(API_CONSTRUCTION_DEED_ITEM, {}, { id, type })
}

export const createConstructionDeedOfTrust = (type: string, data: Record<string, any>) => {
  return Api.post(API_CONSTRUCTION_DEED, data, { type })
}

export const updateConstructionDeedOfTrust = (type: string, id: number, data: Record<string, any>) => {
  return Api.put(API_CONSTRUCTION_DEED_ITEM, data, { id, type })
}

export const deleteConstructionDeedOfTrust = (type: string, id: number) => {
  return Api.delete(API_CONSTRUCTION_DEED_ITEM, {}, { id, type })
}
