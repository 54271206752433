import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { getStateGuaranty, updateStateGuaranty } from 'services'
import svgLoading from 'stories/assets/loading.svg'
import { Checkbox } from 'stories/components'

export interface StateGuaranty {
  id: number
  state: string
  mortgage: boolean
  deedOfTrust: boolean
  securityDeed: boolean
  createdAt: string
}

export const StateGuaranty = () => {
  const [isLoading, setLoading] = useState(false)
  const [values, setValues] = useState<StateGuaranty[]>([])

  useEffect(() => {
    setLoading(true)
    getStateGuaranty().then((values: StateGuaranty[]) => {
      setValues(values)
      setLoading(false)
    })
  }, [])

  const onChange = async (newValue: StateGuaranty) => {
    const index = values.findIndex((v) => newValue.id == v.id)
    if (index == -1) return

    setLoading(true)
    await updateStateGuaranty(newValue.id, newValue)

    const newValues = cloneDeep(values)
    newValues[index] = newValue
    setLoading(false)
    setValues(newValues)
  }

  return (
    <div className="Prepayment-Type-Container">
      <div className="flex flex-wrap justify-between">
        <h1 className="text-2xl font-variation-settings-600 flex items-center pb-5">
          State Guaranty
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h1>
      </div>

      <div className="relative overflow-auto shadow-md sm:rounded-lg">
        <LayoutLoading show={isLoading} />
        <table className="text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-3 w-[40px]">
                No
              </th>
              <th scope="col" className="px-2 py-3">
                State
              </th>
              <th scope="col" className="px-2 py-3 text-center">
                Mortgage allowed
              </th>
              <th scope="col" className="px-2 py-3 text-center">
                Deed of trust allowed
              </th>
              <th scope="col" className="px-2 py-3 text-center">
                Security Deed Allowed
              </th>
            </tr>
          </thead>
          <tbody className="text-[14px] text-gray-900">
            {values
              .sort((a, b) => a.id - b.id)
              .map((value, index) => {
                return (
                  <tr key={`${value.id}-${index}`} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">{index + 1}</td>

                    <td className="px-2 py-2">{value.state}</td>

                    <td className="px-2 py-2 text-center">
                      <Checkbox
                        value={value.mortgage}
                        id={`${index}-mortgage`}
                        color="gray"
                        onChange={(v) => onChange({ ...value, mortgage: v })}
                      />
                    </td>

                    <td className="px-2 py-2 text-center">
                      <Checkbox
                        value={value.deedOfTrust}
                        id={`${index}-deedOfTrust`}
                        color="gray"
                        onChange={(v) => onChange({ ...value, deedOfTrust: v })}
                      />
                    </td>

                    <td className="px-2 py-2 text-center">
                      <Checkbox
                        value={value.securityDeed}
                        id={`${index}-securityDeed`}
                        color="gray"
                        onChange={(v) => onChange({ ...value, securityDeed: v })}
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
