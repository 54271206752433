import { API_STATE_GUARANTY, API_STATE_GUARANTY_ITEM } from 'config'
import Api from 'services/api'

export const getStateGuaranty = () => {
  return Api.get(API_STATE_GUARANTY)
}

export const updateStateGuaranty = (id: number, data: Record<string, any>) => {
  return Api.put(API_STATE_GUARANTY_ITEM, data, { id })
}
