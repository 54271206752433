import { cloneDeep } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'utils/pageTitle'

import {
  ConstructionDeedOfTrust,
  ConstructionDeedOfTrustCategory,
  ConstructionDeedType,
  RetailConstructionDeedType,
} from './ConstructionDeedOfTrust'
import { Placeholders } from './Placeholders'
import { PrepaymentTypes } from './PrepaymentTypes'
import { StateBankDepartments } from './StateBankDepartment'
import { StateGuaranty } from './StateGuaranty'
import { StateVerbiages } from './StateVerbiage'
import { StateVerbiageNotes } from './StateVerbiageNotes'
import { VerbiageNoteTitles } from './types'

interface ParentMenu {
  title: string
  menus: Record<string, string | ParentMenu>
  isOpened?: boolean
}

export function AdminTools() {
  useTitle('Admin Tools')

  const navigate = useNavigate()
  const [selectedMenu, setSelectedMenu] = useState('')
  const [menus, setMenus] = useState<ParentMenu>({
    title: 'main',
    menus: {},
  })

  useEffect(() => {
    const wholesaleDocumentsMenus: Record<string, string> = {}
    ConstructionDeedType.forEach(
      (type: string) => (wholesaleDocumentsMenus[`${ConstructionDeedOfTrustCategory.Wholesale}-${type}`] = type),
    )

    const retailDocumentsMenus: Record<string, string> = {}
    RetailConstructionDeedType.forEach(
      (type: string) => (retailDocumentsMenus[`${ConstructionDeedOfTrustCategory.Retail}-${type}`] = type),
    )

    const menus: Record<string, string | ParentMenu> = {
      placeholders: 'Placeholders',
      prepaymentTypes: 'Prepayment Types',
      stateGuaranty: 'State Guaranty',
      stateVerbiage: 'State Verbiage Security Instrument',
      stateVerbiageNote: VerbiageNoteTitles['stateVerbiageNote'],
      stateBankDepartment: 'State Bank Department',
      guarantyStateVerbiage: VerbiageNoteTitles['guarantyStateVerbiage'],
      guarantyCommunityPropertyVerbiage: VerbiageNoteTitles['guarantyCommunityPropertyVerbiage'],
      wholesaleDocuments: {
        title: 'Wholesale Documents',
        menus: wholesaleDocumentsMenus,
      },
      retailDocuments: {
        title: 'Retail Documents',
        menus: retailDocumentsMenus,
      },
    }

    setMenus({
      title: 'main',
      menus,
    })
    setMenu(Object.keys(menus)[0])
  }, [])

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
    navigate(`/adminTool/${menuItem}`)
  }

  const renderFragment = useMemo(() => {
    if (selectedMenu.includes('-')) {
      const pos = selectedMenu.indexOf('-')
      const category = selectedMenu.substring(0, pos)
      const menu = selectedMenu.substring(pos + 1, selectedMenu.length)

      if (ConstructionDeedType.includes(menu))
        return (
          <ConstructionDeedOfTrust
            key={selectedMenu}
            category={category as ConstructionDeedOfTrustCategory}
            type={menu}
          />
        )
    }

    switch (selectedMenu) {
      case 'placeholders':
        return <Placeholders />
      case 'prepaymentTypes':
        return <PrepaymentTypes />
      case 'stateGuaranty':
        return <StateGuaranty />
      case 'stateVerbiage':
        return <StateVerbiages />
      case 'stateVerbiageNote':
      case 'guarantyStateVerbiage':
      case 'guarantyCommunityPropertyVerbiage':
        return <StateVerbiageNotes type={selectedMenu} key={selectedMenu} />
      case 'stateBankDepartment':
        return <StateBankDepartments />
      default: {
        return <div>Comming Soon...</div>
      }
    }
  }, [selectedMenu])

  const updateMenu = (menus: ParentMenu, menuKey: string, isOpened: boolean) => {
    Object.keys(menus.menus).forEach((key) => {
      const menu = menus.menus[key]
      if (typeof menu == 'string') return
      if (key == menuKey) {
        menu.isOpened = isOpened
      } else updateMenu(menu, menuKey, isOpened)
    })
  }

  const toggleMenu = (menuKey: string, isOpened: boolean) => {
    const newMenus = cloneDeep(menus)
    updateMenu(newMenus, menuKey, isOpened)
    setMenus(newMenus)
  }

  const renderMenu = (menu: ParentMenu) => {
    const { menus } = menu
    return (
      <ul className="sidebar-items flex flex-col">
        {Object.keys(menu.menus).map((item: string, index) => {
          const currentMenu = menus[item as keyof typeof menus]
          const isSubMenu = typeof currentMenu == 'string'

          return (
            <li key={index} className="border-b">
              <p
                className={`hover:underline cursor-pointer ${
                  selectedMenu === item ? 'border px-4 py-3 bg-zinc-100' : 'py-3'
                }`}
                onClick={() => {
                  if (isSubMenu) setMenu(item)
                  else toggleMenu(item, !currentMenu.isOpened)
                }}
              >
                {index + 1}. {isSubMenu ? currentMenu : currentMenu.title}
              </p>
              {!isSubMenu && !!currentMenu.isOpened && <div className="pl-6">{renderMenu(currentMenu)}</div>}
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className="admin-tools-container py-6">
      <div className="admin-tools-wrapper max-w-screen-2xl m-auto grid grid-cols-12 gap-6 px-2">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded px-4 pb-20">
          {renderMenu(menus)}
        </div>
        <div className="content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1">{renderFragment}</div>
      </div>
    </div>
  )
}
