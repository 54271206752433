import { useState } from 'react'
import { toast } from 'react-toastify'
import { updateAccountPassword } from 'services'
import { Input, Modal } from 'stories/components'

export const ChangePassword = ({ userId, email, ...props }: { userId: number; email: string; onClose: any }) => {
  const [loading, setLoading] = useState(false)
  const [passwords, setPasswords] = useState({
    new: '',
    confirm: '',
  })
  const [inputErrors, setInputErrors] = useState({
    new: '',
    confirm: '',
  })

  const reset = () => {
    setPasswords({
      new: '',
      confirm: '',
    })
    setInputErrors({
      new: '',
      confirm: '',
    })
  }

  const onChange = (key: string, value: string) => {
    const newPwds = Object.assign({}, passwords) as any
    newPwds[key] = value
    setPasswords(newPwds)

    const newInputErrors = Object.assign({}, inputErrors) as any
    newInputErrors[key] = ''
    setInputErrors(newInputErrors)
  }

  const onSubmit = async () => {
    const errors = {
      new: '',
      confirm: '',
    }
    if (!passwords.new) errors.new = 'Required'
    if (!passwords.confirm) errors.confirm = 'Required'
    if (passwords.new && passwords.new != passwords.confirm) errors.confirm = 'Not same as new password'
    if (errors.new || errors.confirm) {
      setInputErrors(errors)
      return
    }

    setLoading(true)
    await updateAccountPassword(userId, passwords.new)
    setLoading(false)
    toast('Password is updated', { type: 'info' })
    reset()
    props.onClose()
  }

  const onClose = () => {
    reset()
    props.onClose()
  }

  return (
    <form>
      <Modal title="Change Password" titleOkay="Save" loading={loading} isOpen onClose={onClose} onOk={onSubmit}>
        <div className="grid gap-x-3 grid-cols-1 w-96">
          <label className="mb-3">{email}</label>
          <Input
            title="New Password"
            type="password"
            error={inputErrors.new}
            value={passwords.new}
            required
            onChange={(value) => onChange('new', value)}
          />
          <Input
            title="Confirm Password"
            type="password"
            error={inputErrors.confirm}
            value={passwords.confirm}
            required
            onChange={(value) => onChange('confirm', value)}
          />
        </div>
      </Modal>
    </form>
  )
}
