export interface ConstructionDeed {
  id: number

  // title: string

  value: string

  // label: string

  defaultValue: string

  stateValues: Record<string, string>

  createdAt: Date
}

export const ConstructionDeedType = [
  'Construction Deed of Trust',
  'Deed of Trust',
  'Construction Loan Agreement',
  'Loan Agreement',
  'Construction Note',
  'Note',
  'Construction Mortgage',
  'Mortgage',
  'Guaranty',
]

export const RetailConstructionDeedType = ['Deed of Trust', 'Mortgage', 'Note']

export enum ConstructionDeedOfTrustCategory {
  Wholesale = 'wholesale',
  Retail = 'retail',
}
