import { API_STATE_VERBIAGE, API_STATE_VERBIAGE_ITEM } from 'config'
import Api from 'services/api'

export const getStateVerbiages = () => {
  return Api.get(API_STATE_VERBIAGE)
}

export const updateStateVerbiage = (id: number, data: Record<string, any>) => {
  return Api.put(API_STATE_VERBIAGE_ITEM, data, { id })
}
