import type { InputType, ModalProps } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { deleteDocuments, getDocumentNames } from 'services'
import { svgSearch } from 'stories/assets'
import { Input2, Modal } from 'stories/components'
import { confirm } from 'utils'
import { RenderInput } from 'utils/RenderInput'

interface Document {
  id: number
  name: string
}

export const MultiRemove = (props: ModalProps) => {
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [inputs, setInputs] = useState<Record<number, InputType>>({})

  useEffect(() => {
    setLoading(true)
    getDocumentNames()
      .then((documents: Document[]) => {
        const inputs: Record<number, InputType> = {}
        documents.forEach((document) => {
          inputs[document.id] = {
            inputType: 'checkbox',
            title: document.name,
            value: false,
          }
        })
        setInputs(inputs)
      })
      .finally(() => setLoading(false))
  }, [])

  const selectedIds = useMemo(() => {
    return Object.keys(inputs)
      .filter((key) => inputs[Number(key)].value)
      .map((key) => Number(key))
  }, [inputs])

  const onChangeInputs = (key: number, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onOk = async () => {
    const documents = selectedIds.map((key) => inputs[key].title)

    const content = (
      <div className="mb-5 confirmModalLoanChange">
        <div className="text-[18px] font-bold mb-4">{`Are you sure want to remove below documents?`}</div>
        <div className="text-left text-[13px] mb-4">
          {documents.map((name, index) => (
            <>
              {index + 1}. {name}
              <br />
            </>
          ))}
        </div>
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    setLoading(true)

    deleteDocuments(selectedIds)
      .then(() => props.onClose(true))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      isOpen
      title="Remove Documents"
      titleOkay="Remove"
      onOk={onOk}
      onClose={() => props.onClose(false)}
      disabled={selectedIds.length == 0}
      loading={isLoading}
    >
      <div className="text-gray-600 text-md w-144 gap-2">
        <Input2
          type="search"
          title="Search ..."
          hasIcon
          icon={svgSearch}
          value={query}
          onChange={(value) => setQuery(value)}
          className="mb-4"
        />
        <div className="h-96 overflow-y-scroll">
          {Object.keys(inputs)
            .filter((key) => !query || inputs[Number(key)].title.toLowerCase().includes(query.toLowerCase()))
            .map((key, index) => {
              const input = inputs[Number(key)]

              return (
                <div key={index} className="input border-b px-1">
                  <RenderInput
                    input={{
                      ...input,
                      title: `${index + 1}. ${input.title}`,
                    }}
                    Key={key}
                    onChange={onChangeInputs}
                  />
                </div>
              )
            })}
        </div>
      </div>
    </Modal>
  )
}
