import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Checkbox, Modal } from 'stories/components'

type Function = () => void

interface UnknownPlaceholderModalProps {
  placeholders: string[]
  onOk?: Function
  onCancel?: Function
}

/**
 * Primary UI component for user interaction
 */
export const UnknownPlaceholderModal = ({
  placeholders,
  onOk = () => {},
  onCancel = () => {},
}: UnknownPlaceholderModalProps) => {
  const [isChecked, setChecked] = useState(false)

  return (
    <Modal
      isOpen
      lastUpdatedAt={Date.now()}
      disabled={!isChecked}
      titleOkay="Continue"
      onOk={onOk}
      onCancel={onCancel}
      title="Confirm"
    >
      <div className="px-6 text-center">
        <InformationCircleIcon className="mx-auto mb-4 w-14 h-14 text-red-500 dark:text-gray-200" />
        <div className="mb-2">
          <p>This document includes unknown placeholders</p>
          <p className="font-variation-settings-600">[{placeholders.join(', ')}]</p>
        </div>

        <div className="mb-2">
          <Checkbox
            id="continue"
            checked={isChecked}
            onChange={(v) => setChecked(v)}
            title="Add new placeholders and continue to submit"
          />
        </div>
      </div>
    </Modal>
  )
}
