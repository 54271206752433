import type { InputType } from 'config'
import { EntityOptions, FilterOptions } from 'pages/Pipeline/constant'

export const documentOptions = ['entity']

export const defaultInputs = (): Record<string, InputType> => {
  return {
    name: {
      title: 'File name',
      inputType: 'text',
      error: '',
      required: true,
    },

    entitySection: {
      inputType: 'section',
      title: 'Entity',
      span: 3,
    },
    entity: {
      title: 'Entity Options',
      inputType: 'multiselectpanel',
      options: EntityOptions,
      error: '',
      span: 3,
    },
    entityLevel: {
      title: 'Entity Filter Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    fileSection: {
      inputType: 'section',
      title: 'Template file',
      span: 3,
    },
    file: {
      title: 'Choose file',
      inputType: 'file',
      error: '',
      acceptFileTypes: '.doc,.docx',
      required: true,
      multiple: true,
      span: 3,
    },
  }
}
