import { ArrowDownTrayIcon, CheckIcon, EyeIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { updateCountPerPage } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage as _itemCountPerPage } from 'config'
import { states } from 'config/states.constants'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  deleteDocument,
  filterPipeline,
  openS3Document,
  updateDocumentNotes,
  updateDocumentOrder,
  updateDocumentProgress,
  updateDocumentStatus,
} from 'services'
import { svgSearch } from 'stories/assets'
import { Button, ButtonGroup, Input, Input2, Pagination, Select, Select2, TextArea, Toggle } from 'stories/components'
import { confirm, formatDate } from 'utils'
import { useTitle } from 'utils/pageTitle'
import { renderHeader } from 'utils/table'

import {
  AmortizationTypeOptions,
  BorrowersOptions,
  ConstructionOptions,
  EntityOptions,
  InterestCalculationOptions,
  LoanPurposeOptions,
  OccupancyOptions,
  PrepayOptions,
} from './constant'
import { MultiRemove } from './MultiRemove'
// import { PdfPlaceholderDialog } from './PdfPlaceholderDialog'

export interface Document {
  id: number
  name: string
  fileKey: string
  placeholders: string[]
  options: Record<string, string[]>
  properties: Record<string, Record<string, any>>
  updatedAt: string
  order: number
  status: boolean
  progress: DocumentProgress
  notes: string
  type: DocumentType
}

export enum DocumentProgress {
  InReview = 0,
  Approved = 1,
  Invalid = -1,
}

export enum DocumentType {
  Original = 'original',
  Postclosing = 'postclosing',
  Servicing = 'servicing',
}

const DocumentProgressOptions: Record<string, string> = {
  '0': 'In Review',
  '1': 'Approved',
  '-1': 'Invalid',
}

export const Pipeline = () => {
  const [isLoading, setLoading] = useState(false)
  // const [isShowRender, setShowRender] = useState(false)
  // const [selectedDoc, setSelectedDoc] = useState<Document | null>(null)
  const [list, setList] = useState<Document[]>([])
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    state: null,
    loanPurpose: null,
    occupancy: null,
    entity: null,
    prepay: null,
    amortizationType: null,
    construction: null,
    interestCalculation: null,
    borrowers: null,
    progress: null,
    orderBy: 'order',
    orderDir: '1',
    type: DocumentType.Original,
  })
  // const [lastFilterData, setLastFilterData] = useState<string>('')
  const { itemCountPerPage = _itemCountPerPage } = useSelector((state: any) => state.auth)
  const [total, setTotal] = useState(0)
  const [action, setAction] = useState('')
  const [pageNum, setPageNum] = useState(0)
  const [_countPerPage, setCountPerPage] = useState(itemCountPerPage)
  const [filterQuery, setFilterQuery] = useState('')
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [tableName, setTableName] = useState('Pipeline')
  const [orders, setOrders] = useState<Record<number, number>>({})
  const [notes, setNotes] = useState<Record<number, string>>({})
  const [isMultiRemove, setMultiRemove] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useTitle(`Pipeline`)

  useEffect(() => {
    if (action) return
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [pageNum, _countPerPage])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => action == '' && filterData(filters, 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const onChangeFilter = (key: string, value: any, needRefetch = true) => {
    if (!isGetUsersOnce) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (needRefetch) {
      filterData(newFilters, 0)
      setPageNum(0)
    }
  }

  const filterData = async (filters: any, _pageNum: number = -1) => {
    if (action) return
    if (_pageNum === -1) _pageNum = pageNum
    const filterData = {
      ...filters,
      state: filters.state,
      loanPurpose: filters.loanPurpose,
      occupancy: filters.occupancy,
      entity: filters.entity,
      prepay: filters.prepay,
      amortizationType: filters.amortizationType,
      construction: filters.construction,
      interestCalculation: filters.interestCalculation,
      skip: pageNum * _countPerPage,
      count: _countPerPage,
    }
    // if (JSON.stringify(filterData) == lastFilterData) return

    setAction('getLoans')
    setLoading(true)
    // setLastFilterData(JSON.stringify(filterData))
    const { data, total } = await filterPipeline(filterData)
    setList(data)
    setTotal(total)
    setLoading(false)
    setAction('')

    const orders: Record<number, number> = {}
    data.map((doc: Document) => {
      orders[doc.id] = doc.order
    })
    setOrders(orders)

    const notes: Record<number, string> = {}
    data.map((doc: Document) => {
      notes[doc.id] = doc.notes
    })
    setNotes(notes)
  }

  const getOptionText = (item: Document, key: string) => {
    if (item.options && item.options[key]) return item.options[key].join(', ')
    return ''
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onChangeCountPerPage = (count: number) => {
    dispatch(updateCountPerPage(count))
    setCountPerPage(count)
    setPageNum(0)
  }

  const onChangeTable = (value: string) => {
    setTableName(value)
  }

  const onSetOrder = (id: number, v: string) => {
    const newOrders = cloneDeep(orders)
    newOrders[id] = Number(v)
    setOrders(newOrders)
  }

  const onUpdateOrder = async (id: number) => {
    setLoading(true)
    await updateDocumentOrder(id, orders[id])
    filterData(filters)
  }

  const onSetNotes = (id: number, v: string) => {
    const newNotes = cloneDeep(notes)
    newNotes[id] = v
    setNotes(newNotes)
  }

  const onUpdateNotes = async (id: number, index: number) => {
    if (notes[id] == list[index].notes) return

    setLoading(true)
    await updateDocumentNotes(id, notes[id])
    filterData(filters)
  }

  const onUpdateStatus = async (id: number, status: boolean, index: number) => {
    setLoading(true)
    await updateDocumentStatus(id, status)
    const newList = cloneDeep(list)
    newList[index].status = status
    setList(newList)
    setLoading(false)
  }

  const onNewPdf = () => {
    navigate('/pipeline/new')
  }

  const onSort = (key: string, sortOrder: number) => {
    if (sortOrder == 0) sortOrder = -1
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)
    filterData(newFilters)
  }

  const onDelete = async (item: Document) => {
    const result = await confirm('Do you want to remove this template?')
    if (!result) return

    setLoading(true)
    await deleteDocument(item.id)
    filterData(filters)
    setLoading(false)
  }

  const onDeletes = () => {
    setMultiRemove(true)
  }

  const onUpdateProgress = async (index: number, value: string) => {
    const newList = cloneDeep(list)
    newList[index].progress = Number(value) as DocumentProgress
    setList(newList)

    setLoading(true)
    await updateDocumentProgress(newList[index].id, value)
    setLoading(false)
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="flex flex-wrap items-center gap-2 mb-3">
          <h1 className="text-2xl font-variation-settings-600">
            <span>Pipeline</span>
          </h1>
          <ButtonGroup title={['Pipeline', 'Order']} value={tableName} onChange={onChangeTable} />
          <div className="flex-1" />
          <Button onClick={onNewPdf}>New Doc</Button>
        </div>
        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-3">
          <Select2
            id="state"
            title="State"
            options={states}
            value={filters.state}
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('state', value)}
          />
          <Select2
            id="loanPurpose"
            title="Loan Purpose"
            options={LoanPurposeOptions}
            value={filters.loanPurpose}
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('loanPurpose', value)}
          />
          <Select2
            id="occupancy"
            title="Occupancy"
            options={OccupancyOptions}
            value={filters.occupancy}
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('occupancy', value)}
          />
          <Select2
            id="entity"
            title="Entity"
            options={EntityOptions}
            value={filters.entity}
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('entity', value)}
          />
          <Select2
            id="prepay"
            title="Prepay"
            options={PrepayOptions}
            value={filters.prepay}
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('prepay', value)}
          />
          <Select2
            id="amortizationType"
            title="Amotization Type"
            options={AmortizationTypeOptions}
            value={filters.amortizationType}
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('amortizationType', value)}
          />
          <Select2
            id="construction"
            title="Construction"
            options={ConstructionOptions}
            value={filters.construction}
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('construction', value)}
          />
          <Select2
            id="interestCalculation"
            title="Interest Calculation"
            options={InterestCalculationOptions}
            value={filters.interestCalculation}
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('interestCalculation', value)}
          />
          <Select2
            id="borrowers"
            title="Borrowers"
            options={BorrowersOptions}
            value={filters.borrowers}
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('borrowers', value)}
          />
          <Input2
            type="search"
            title="Search ..."
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
          <Select2
            id="progress"
            title="Progress"
            options={DocumentProgressOptions}
            value={filters.progress}
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('progress', value)}
          />
        </div>

        <div className="table-container relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-2">No</th>
                {renderHeader({
                  title: 'Name',
                  key: 'name',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'name' ? parseInt(filters.orderDir) : 0,
                })}
                {tableName === 'Pipeline' && (
                  <>
                    <th className="px-2">State</th>
                    <th className="px-2">Loan Purpose</th>
                  </>
                )}
                {tableName === 'Order' &&
                  renderHeader({
                    title: 'Notes',
                    key: 'notes',
                    index: 0,
                    onSort,
                    sortable: true,
                    sortOrder: filters.orderBy == 'notes' ? parseInt(filters.orderDir) : 0,
                  })}
                {tableName === 'Order' &&
                  renderHeader({
                    title: 'Progress',
                    key: 'progress',
                    index: 0,
                    onSort,
                    sortable: true,
                    sortOrder: filters.orderBy == 'progress' ? parseInt(filters.orderDir) : 0,
                  })}
                {renderHeader({
                  title: 'Order',
                  key: 'order',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'order' ? parseInt(filters.orderDir) : 0,
                })}
                {renderHeader({
                  title: 'Updated At',
                  key: 'updatedAt',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'updatedAt' ? parseInt(filters.orderDir) : 0,
                })}
                {tableName === 'Pipeline' && <th className="px-2">Actions</th>}
                <th className="px-2">Status</th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {list.map((item: Document, index: number) => {
                return (
                  <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      <span className="flex-col flex">{index + 1 + pageNum * _countPerPage}</span>
                    </td>
                    <td className="px-2 font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      <a href={`/pipeline/${item.id}`} target="_blank">{`${item.name}`}</a>
                    </td>

                    {tableName === 'Pipeline' ? (
                      <>
                        <td className="px-2 py-2">
                          <p className="text-ellipsis overflow-hidden w-[170px] whitespace-nowrap">
                            {getOptionText(item, 'state')}
                          </p>
                        </td>
                        <td className="px-2 py-2">
                          <p className="text-ellipsis overflow-hidden w-[170px] whitespace-nowrap">
                            {getOptionText(item, 'loanPurpose')}
                          </p>
                        </td>
                        <td className="px-2 py-2">{item.order}</td>
                      </>
                    ) : (
                      <>
                        <td className="px-2 py-2">
                          <div className="">
                            <TextArea
                              value={notes[item.id]}
                              rows={2}
                              onChange={(value) => onSetNotes(item.id, value)}
                              onBlur={() => onUpdateNotes(item.id, index)}
                              className=""
                            />
                          </div>
                        </td>
                        <td className="px-2 py-2">
                          <Select
                            id={`progress-${item.id}`}
                            options={DocumentProgressOptions}
                            value={`${item.progress}`}
                            onChange={(value) => onUpdateProgress(index, value)}
                            className="-mb-4"
                          />
                        </td>
                        <td className="px-2 py-2 w-24">
                          <div className="flex items-center gap-2">
                            <Input
                              type="number"
                              value={`${orders[item.id]}`}
                              onChange={(v) => onSetOrder(item.id, v)}
                              className="w-16"
                            />
                            {orders[item.id] != item.order && (
                              <span
                                className="inline-block text-shade-blue p-1 hover-shadow1 cursor-pointer"
                                onClick={() => onUpdateOrder(item.id)}
                              >
                                <CheckIcon className="w-4 h-4" />
                              </span>
                            )}
                          </div>
                        </td>
                      </>
                    )}
                    <td className="px-2 py-2">{`${formatDate(item.updatedAt)}`}</td>

                    {tableName === 'Pipeline' && (
                      <td className="px-2 py-2">
                        <span className="flex gap-1">
                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => openS3Document(item.fileKey, true)}
                          >
                            <EyeIcon className="w-4 h-4" />
                          </span>

                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => openS3Document(item.fileKey)}
                          >
                            <ArrowDownTrayIcon className="w-4 h-4" />
                          </span>

                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => {
                              navigate(`/pipeline/${item.id}`)
                            }}
                          >
                            <PencilSquareIcon className="w-4 h-4" />
                          </span>

                          <span
                            className="text-red-700 p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onDelete(item)}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </span>
                        </span>
                      </td>
                    )}
                    <td className="px-2 py-2 scale-75">
                      <Toggle
                        id={`status-${item.id}`}
                        value={item.status}
                        onChange={(v) => onUpdateStatus(item.id, v, index)}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end items-center mt-3">
          <div className="flex-1" />
          <Pagination
            totalCount={total}
            itemCountPerPage={_countPerPage}
            onNavigate={onPageNavigate}
            onChangeCountPerPage={onChangeCountPerPage}
            pageNum={pageNum}
          />

          <span className="text-red-700 p-1 hover-shadow1 cursor-pointer" onClick={() => onDeletes()}>
            <TrashIcon className="w-4 h-4" />
          </span>
        </div>
      </div>
      {/* {isShowNewPdf && (
        <NewPdfDialog doc={selectedDoc} onSubmit={onSubmitNewPdf} onClose={() => setShowNewPdf(false)} />
      )} */}
      {/* {isShowRender && selectedDoc && <PdfPlaceholderDialog doc={selectedDoc} onClose={() => setShowRender(false)} />} */}
      {isMultiRemove && (
        <MultiRemove
          onClose={(result: boolean) => {
            result && filterData(filters, 0)
            setMultiRemove(false)
          }}
        />
      )}
    </div>
  )
}
