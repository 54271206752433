import { AdminTools } from 'pages/AdminTool'
import { DocSign } from 'pages/DocSign'
import { DocSignPacakgeDetail } from 'pages/DocSign/DocSignPackageDetail'
import { ManageAccounts } from 'pages/ManageAccounts'
import { Pipeline } from 'pages/Pipeline'
import { SingleDocument } from 'pages/Pipeline/SingleDocument'
import { PostclosingPipeline, PostclosingPipelineDocument } from 'pages/PostclosingPipeline'
import { ServicingPipeline, ServicingPipelineDocument } from 'pages/ServicingPipeline'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

export function MainRoute() {
  const auth = useSelector((state: any) => state.auth)
  if (!auth.isAuthenticated) return null

  return (
    <Routes>
      <Route path="/postclosingPipeline" element={<PostclosingPipeline />} />
      <Route path="/postclosingPipeline/item" element={<PostclosingPipelineDocument />} />
      <Route path="/servicingPipeline" element={<ServicingPipeline />} />
      <Route path="/servicingPipeline/item" element={<ServicingPipelineDocument />} />
      <Route path="/sign" element={<DocSign />} />
      <Route path="/sign/item" element={<DocSignPacakgeDetail />} />
      <Route path="/pipeline" element={<Pipeline />} />
      <Route path="/pipeline/:id" element={<SingleDocument />} />
      <Route path="/manageAccounts" element={<ManageAccounts />} />
      <Route path="/adminTool" element={<Navigate to="/adminTool/placeholders" replace />} />
      <Route path="/adminTool/:menu" element={<AdminTools />} />
      <Route path="*" element={<Navigate to="/pipeline" replace />} />
    </Routes>
  )
}
