import { Login } from 'pages'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

export function AuthRoute() {
  const auth = useSelector((state: any) => state.auth)
  if (auth.isAuthenticated) return null

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  )
}
