import { ListBulletIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { deletePlaceholder, getPlaceholders, updatePlaceholder } from 'services'
import { svgSearch } from 'stories/assets'
import svgLoading from 'stories/assets/loading.svg'
import { Button, Checkbox, Input, TextArea, Tooltip } from 'stories/components'
import { confirm, formatDate } from 'utils'
import { renderHeader } from 'utils/table'

import { PlaceholderDetails } from './details'
import { NewPlaceholderDialog } from './NewPlaceholderDialog'

export interface Placeholder {
  id: number
  value: string
  count?: number
  description: string
  createdBy?: string
  createdAt: string
  isUpdated?: boolean
}

export const Placeholders = () => {
  const [isLoading, setLoading] = useState(false)
  const [isShowAdd, setShowAdd] = useState(false)
  const [values, setValues] = useState<Placeholder[]>([])
  const [activeId, setActiveId] = useState(0)
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    showDescription: false,
    orderBy: 'value',
    orderDir: 1,
  })

  useEffect(() => {
    setLoading(true)
    getPlaceholders().then((values: Placeholder[]) => {
      setValues(values)
      setLoading(false)
    })
  }, [])

  const onAdd = () => {
    setShowAdd(true)
  }

  const onAddSubmit = (item: Placeholder) => {
    const newValues = cloneDeep(values)
    newValues.push({
      ...item,
      count: 0,
    })
    setValues(newValues)
    setShowAdd(false)
  }

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
  }

  const onSort = (key: string, sortOrder: number) => {
    if (sortOrder == 0) sortOrder = -1
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)
  }

  const onDelete = async (item: Placeholder) => {
    if (item.id) {
      const result = await confirm('Do you want to remove this placeholder?')
      if (!result) return
    }

    const index = values.findIndex((v) => {
      if (item.id) return v.id === item.id
      else v.createdAt === item.createdAt
    })
    if (index === -1) return

    setLoading(true)
    if (item.id) await deletePlaceholder(item.id)

    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
    setLoading(false)
  }

  const onExpand = (item: Placeholder) => {
    if (activeId == item.id) setActiveId(0)
    else setActiveId(item.id)
  }

  const onChangeDescription = (id: number, value: string) => {
    const newValues = cloneDeep(values)
    const index = newValues.findIndex((p) => p.id == id)
    if (index == -1 || newValues[index].description == value) return

    newValues[index].description = value
    newValues[index].isUpdated = true
    setValues(newValues)
  }

  const onUpdateDescription = async (id: number) => {
    const newValues = cloneDeep(values)
    const index = newValues.findIndex((p) => p.id == id)

    if (!newValues[index].isUpdated) return
    setLoading(true)
    await updatePlaceholder(id, newValues[index].value, newValues[index].description)

    newValues[index].isUpdated = false
    setValues(newValues)
    setLoading(false)
  }

  return (
    <div>
      <div className="flex flex-wrap justify-between">
        <div className="flex pb-5 items-center gap-2">
          <h1 className="text-2xl font-variation-settings-600">Placeholders</h1>
          <span className="text-md">({values.length})</span>
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </div>
        <Button onClick={onAdd}>+ Add</Button>
      </div>

      <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
        <Input
          type="search"
          title="Search ..."
          hasIcon
          icon={svgSearch}
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />
        <Checkbox
          id="showDescription"
          title="Description"
          value={filters.showDescription}
          onChange={(value) => onChangeFilter('showDescription', value)}
        />
      </div>

      <div className="overflow-auto shadow-md sm:rounded-lg">
        <LayoutLoading show={isLoading} />
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-3 w-[40px]">
                No
              </th>
              {renderHeader({
                title: 'Name',
                key: 'value',
                index: 0,
                onSort,
                sortable: true,
                sortOrder: filters.orderBy == 'value' ? parseInt(filters.orderDir) : 0,
              })}
              {renderHeader({
                title: 'Count',
                key: 'count',
                index: 0,
                onSort,
                sortable: true,
                sortOrder: filters.orderBy == 'count' ? parseInt(filters.orderDir) : 0,
              })}
              {!filters.showDescription ? (
                <>
                  <th scope="col" className="px-2 py-3 w-[150px]">
                    Created By
                  </th>
                  {renderHeader({
                    title: 'Created At',
                    key: 'createdAt',
                    index: 0,
                    onSort,
                    sortable: true,
                    sortOrder: filters.orderBy == 'createdAt' ? parseInt(filters.orderDir) : 0,
                    className: 'w-[150px]',
                  })}
                  <th scope="col" className="px-2 py-3 w-[100px]">
                    Action
                  </th>
                </>
              ) : (
                <th scope="col" className="px-2 py-3">
                  Description
                </th>
              )}
            </tr>
          </thead>
          <tbody className="text-[14px] text-gray-900">
            {values
              .filter((v) => {
                const { query } = filters
                if (!query) return true
                return (
                  (v.value && v.value.toLowerCase().includes(query.toLowerCase())) ||
                  (v.description && v.description.toLowerCase().includes(query.toLowerCase()))
                )
              })
              .sort((a: any, b: any) => (a[filters.orderBy] > b[filters.orderBy] ? 1 : -1) * filters.orderDir)
              .map((value, index) => {
                const isActive = activeId == value.id
                const hasDocument = !!value.count
                const hasSpace = value.value.match(/\s$/) || value.value.match(/^\s/)
                return (
                  <>
                    <tr
                      key={`${value.createdAt}-${index}`}
                      className={`border-b ${hasSpace ? 'bg-yellow-100' : index % 2 && 'bg-slate-50'}`}
                    >
                      <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                        {index + 1}
                      </td>

                      <td className="hover:underline cursor-pointer px-2 py-2 w-72">
                        <span onClick={() => onExpand(value)}>{value.value}</span>
                      </td>
                      <td className="px-2 py-2 w-12">{value.count}</td>

                      {!filters.showDescription ? (
                        <>
                          <td className="px-2 py-2">{value.createdBy}</td>

                          <td className="px-2 py-2">{formatDate(value.createdAt)}</td>

                          <td className="px-2 py-2">
                            <span className="flex">
                              {hasDocument ? (
                                <Tooltip message="This placeholder is related with documents">
                                  <span className="text-red-400 p-1">
                                    <TrashIcon className="w-4 h-4"></TrashIcon>
                                  </span>
                                </Tooltip>
                              ) : (
                                <button
                                  className="text-red-700 hover-shadow1 cursor-pointer p-1"
                                  onClick={() => onDelete(value)}
                                >
                                  <TrashIcon className="w-4 h-4"></TrashIcon>
                                </button>
                              )}
                              <span
                                className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                                onClick={() => onExpand(value)}
                              >
                                <ListBulletIcon className="w-4 h-4" />
                              </span>
                            </span>
                          </td>
                        </>
                      ) : (
                        <td className="px-2 py-2">
                          <TextArea
                            rows={2}
                            value={value.description}
                            onChange={(v) => onChangeDescription(value.id, v)}
                            onBlur={() => onUpdateDescription(value.id)}
                          />
                        </td>
                      )}
                    </tr>
                    {isActive && (
                      <tr>
                        <td />
                        <td colSpan={6}>
                          <PlaceholderDetails data={value} onClose={() => setActiveId(0)} />
                        </td>
                      </tr>
                    )}
                  </>
                )
              })}
          </tbody>
        </table>
        {isShowAdd && <NewPlaceholderDialog onSubmit={onAddSubmit} onClose={() => setShowAdd(false)} />}
      </div>
    </div>
  )
}
