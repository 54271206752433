import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { Loading } from 'components/Loading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { deleteConstructionDeedOfTrust, getPlaceholderValues, listConstructionDeedOfTrust } from 'services'
import { svgSearch } from 'stories/assets'
import { Button, Input } from 'stories/components'
import { confirm, formatDate } from 'utils'

import { NewAddPage } from './NewAddPage'
import type { ConstructionDeed, ConstructionDeedOfTrustCategory } from './type'

export * from './type'

export const ConstructionDeedOfTrust = ({
  category,
  type,
}: {
  category: ConstructionDeedOfTrustCategory
  type: string
}) => {
  const [isLoading, setLoading] = useState(false)
  const [isShowAdd, setShowAdd] = useState(false)
  const [query, setQuery] = useState('')
  const [values, setValues] = useState<ConstructionDeed[]>([])
  const [selectedItem, setSelectedItem] = useState<ConstructionDeed | null>(null)
  const [placeholders, setPlaceholders] = useState<string[]>([])

  useEffect(() => {
    setLoading(true)
    listConstructionDeedOfTrust(category, type).then((values: ConstructionDeed[]) => {
      setValues(values)
      setLoading(false)
    })
    getPlaceholderValues().then((v) => setPlaceholders(v))
  }, [])

  const onAdd = () => {
    setShowAdd(true)
    setSelectedItem(null)
  }

  const onUpdate = (item: ConstructionDeed) => {
    setShowAdd(true)
    setSelectedItem(item)
  }

  const onItemSubmit = (item: ConstructionDeed) => {
    const newValues = cloneDeep(values)
    if (!selectedItem) newValues.push(item)
    else {
      const index = values.findIndex((v) => v.id == item.id)
      newValues[index] = item
    }
    setValues(newValues)
    setShowAdd(false)
  }

  const onDelete = async (item: ConstructionDeed) => {
    if (item.id) {
      const result = await confirm(
        <>
          Do you want to remove this data?
          <br />
          <p className="font-semibold mb-4">Placeholder: {item.value}</p>
        </>,
      )
      if (!result) return
    }

    const index = values.findIndex((v) => v.createdAt === item.createdAt)
    if (index === -1) return

    setLoading(true)
    if (item.id) await deleteConstructionDeedOfTrust(type, item.id)

    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
    setLoading(false)
  }

  if (isShowAdd)
    return (
      <NewAddPage
        placeholders={placeholders}
        category={category}
        type={type}
        item={selectedItem}
        onSubmit={onItemSubmit}
        onClose={() => setShowAdd(false)}
      />
    )

  return (
    <div className={`${type.replace(/ /g, '-')}-Container`}>
      <div className="flex flex-wrap justify-between">
        <h1 className="text-2xl font-variation-settings-600 flex items-center pb-5">
          {type}
          {isLoading && <Loading />}
        </h1>
        <Button onClick={onAdd}>+ Add</Button>
      </div>

      <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
        <Input
          type="search"
          title="Search ..."
          hasIcon
          icon={svgSearch}
          value={query}
          onChange={(value) => setQuery(value)}
        />
      </div>

      <div className="relative overflow-auto shadow-md sm:rounded-lg">
        <LayoutLoading show={isLoading} />
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-3 w-[40px]">
                No
              </th>
              <th scope="col" className="px-2 py-3">
                Placeholder
              </th>
              <th scope="col" className="px-2 py-3">
                Default Value
              </th>
              <th scope="col" className="px-2 py-3 w-[100px]">
                Created At
              </th>
              <th scope="col" className="px-2 py-3 w-[70px]">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-[14px] text-gray-900">
            {values
              .filter(
                (v) =>
                  v.value.toLowerCase().includes(query.toLowerCase()) ||
                  v.defaultValue.toLowerCase().includes(query.toLowerCase()),
              )
              .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
              .map((value, index) => {
                return (
                  <tr key={`${value.createdAt}-${index}`} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      {index + 1}
                    </td>

                    <td className="px-2 py-2">{value.value}</td>

                    <td className="px-2 py-2">
                      <p>{value.defaultValue}</p>
                    </td>

                    <td className="px-2 py-2">{formatDate(value.createdAt)}</td>

                    <td className="px-2 py-2">
                      <span className="flex">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => onUpdate(value)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>

                        <span className="text-red-700 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(value)}>
                          <TrashIcon className="w-4 h-4"></TrashIcon>
                        </span>
                      </span>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
