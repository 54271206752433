import { API_STATE_VERBIAGE_NOTE, API_STATE_VERBIAGE_NOTE_ITEM } from 'config'
import Api from 'services/api'

export const getStateVerbiageNotes = (type: string) => {
  return Api.get(API_STATE_VERBIAGE_NOTE, {}, { type })
}

export const updateStateVerbiageNote = (id: number, data: Record<string, any>) => {
  return Api.put(API_STATE_VERBIAGE_NOTE_ITEM, data, { id })
}
