import { API_DOCSIGN_GET_DOCUMENTS, API_DOCSIGN_GET_PACKAGES, API_DOCSIGN_GET_PARTICIPANTS } from 'config'
import Api from 'services/api'

export const filterPackages = (filters: Record<string, any>) => {
  return Api.get(API_DOCSIGN_GET_PACKAGES, filters)
}

export const getDocuments = (packageId: string) => {
  return Api.get(API_DOCSIGN_GET_DOCUMENTS, {}, { packageId })
}

export const getPariticpants = (packageId: string) => {
  return Api.get(API_DOCSIGN_GET_PARTICIPANTS, {}, { packageId })
}
