import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import type { InputType } from 'config'
import { cloneDeep } from 'lodash'
import { Document, DocumentType } from 'pages/Pipeline'
import { UnknownPlaceholderModal } from 'pages/Pipeline/UnknownPlaceholderModal'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { createNewDocument, updateDocument } from 'services'
import { Button } from 'stories/components'
import { InputValidate, trimExtension } from 'utils'
import { useTitle } from 'utils/pageTitle'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, documentOptions } from './constant'

export const ServicingPipelineDocument = () => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())
  const [fileNameInputs, setFileNameInputs] = useState<InputType[]>([])
  const { state } = useLocation() as { state: { doc: Document } }
  const navigate = useNavigate()
  const [unknownPlaceholders, setUnknownPlaceholders] = useState<string[] | null>(null)
  const [warning, setWarning] = useState('')
  useTitle('Document')

  useEffect(() => {
    if (state) {
      const newInputs = cloneDeep(inputs)
      newInputs.name.value = state.doc.name

      for (const key of documentOptions) {
        if (state.doc.options[key]) newInputs[key].value = state.doc.options[key]
        if (state.doc.properties[key] && newInputs[`${key}Level`])
          newInputs[`${key}Level`].value = state.doc.properties[key].isMandatary ? 1 : 0
      }
      if (state.doc.options['email']) {
        newInputs.emailSubject.value = (state.doc.options['email'] as any as Record<string, string>)['subject']
        newInputs.emailComment.value = (state.doc.options['email'] as any as Record<string, string>)['comment']
      }
      newInputs.file.value = state.doc.fileKey

      setInputs(newInputs)
    }
  }, [])

  const isNewDoc = useMemo(() => !state || !state.doc, [state])

  const onBack = () => {
    navigate('/servicingPipeline')
  }

  const onChangeInputs = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''

    if (isNewDoc && key == 'file') {
      const fileNameInputs: InputType[] = []
      const length = (value as any).length
      if (length <= 1) {
        newInputs.name.disabled = false
        setFileNameInputs([])
        if (length == 1) {
          newInputs.name.value = trimExtension((value[0] as any as File).name)
        }
      } else {
        newInputs.name.disabled = true
        for (let i = 0; i < (value as any).length; i++)
          fileNameInputs.push({
            title: 'File name',
            inputType: 'text',
            value: trimExtension((value[i] as any as File).name),
            error: '',
            required: true,
          })
        setFileNameInputs(fileNameInputs)
      }
    }
    setInputs(newInputs)
  }

  const onChangeFileNameInput = (key: string, value: string) => {
    const index = Number(key)
    const newInputs = cloneDeep(fileNameInputs)
    newInputs[index].value = value
    newInputs[index].error = ''
    setFileNameInputs(newInputs)
  }

  const onOk = async (continueWithUnknown = false) => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const inputData: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (value !== undefined) inputData[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    const file = inputData['file']
    const fileNames: string[] = []
    if (isNewDoc && file.length > 1) {
      const newFileNameInputs = cloneDeep(fileNameInputs)
      for (const key in newFileNameInputs) {
        const { value } = newFileNameInputs[key]
        let error = InputValidate(newFileNameInputs[key])
        newFileNameInputs[key].error = error
        if (error.length > 0) hasError = true

        if (fileNames.includes(value) && !error) {
          newFileNameInputs[key].error = 'Duplicated FileName'
          hasError = true
        }
        fileNames.push(value || '')
      }
      if (hasError) {
        setFileNameInputs(newFileNameInputs)
        return
      }
      inputData.name = JSON.stringify(fileNames)
    }

    setLoading(true)
    delete inputData['file']

    const properties: Record<string, Record<string, any>> = {}
    for (const key of documentOptions) {
      properties[key] = {
        isMandatary: inputData[`${key}Level`] == 1,
      }
      if (inputData[`${key}Level`] !== undefined) delete inputData[`${key}Level`]
    }
    inputData.properties = JSON.stringify(properties)
    inputData.continueWithUnknown = continueWithUnknown
    inputData.type = DocumentType.Servicing

    inputData.email = JSON.stringify({
      subject: inputData.emailSubject,
      comment: inputData.emailComment,
    })
    delete inputData.emailSubject
    delete inputData.emailComment

    if (isNewDoc) {
      createNewDocument(inputData, file)
        .then(onUploadDocument)
        .finally(() => setLoading(false))
    } else {
      updateDocument(state.doc.id, inputData, [file])
        .then(onUploadDocument)
        .finally(() => setLoading(false))
    }
  }

  const onUploadDocument = (data: Record<string, any>) => {
    const { success, placeholders = [], warning = '' } = data
    if (success) {
      navigate('/servicingPipeline')
      return
    }
    setUnknownPlaceholders(placeholders)
    setWarning(warning)
  }

  const renderFileNameInputs = () => {
    const files: File[] = inputs.file.value

    if (!files || !files.length || files.length <= 1) return

    return (
      <div className="input mb-3 p-2">
        <label className="block font-variation-settings-600 mb-2">File Names</label>
        {files.map((file, index) => (
          <div className="input mb-2" key={`${index}`}>
            <span className="text-sm">{file.name}</span>
            <RenderInput Key={`${index}`} input={fileNameInputs[index]} onChange={onChangeFileNameInput} />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full">
        <div className="text-shade-blue flex items-center mb-2 font-variation-settings-600">
          <ChevronLeftIcon className="w-4 h-4" />
          <span className="ml-1 cursor-pointer hover:underline" onClick={onBack}>
            Return to Pipeline
          </span>
        </div>
        <LayoutLoading show={isLoading} />

        <div className="flex flex-wrap gap-2 items-center mb-3">
          <h1 className="text-2xl font-variation-settings-600 flex items-center">
            <span>{isNewDoc ? 'Add New' : 'Edit'} Document</span>
          </h1>
        </div>
        <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 mb-2`}>
          <div className="input mb-3">
            <RenderInput input={inputs.name} Key="name" onChange={onChangeInputs} />
          </div>
          <div className="col-span-full" />
          {documentOptions.map((key, index) => {
            const inputSection = inputs[`${key}Section`]
            const input = inputs[key]
            const inputLevel = inputs[`${key}Level`]
            return (
              <div
                key={key}
                className="shadow1 bg-white rounded p-5 md:col-span-3 sm:col-span-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mb-3 gap-3"
              >
                <div className={`input mb-3 ${inputSection.span ? 'md:col-span-3 sm:col-span-2' : ''}`}>
                  <RenderInput
                    input={{
                      ...inputSection,
                      inputType: 'Section',
                      hasMark: false,
                      title: `${index + 1}. ${inputSection.title}`,
                    }}
                    Key={`${key}Section`}
                    onChange={onChangeInputs}
                  />
                </div>
                <div className={`input mb-3 ${input.span ? 'md:col-span-3 sm:col-span-2' : ''}`}>
                  <RenderInput input={input} Key={key} onChange={onChangeInputs} />
                </div>
                {inputLevel && (
                  <div className={`input mb-3 ${inputLevel.span ? 'md:col-span-3 sm:col-span-2' : ''}`}>
                    <RenderInput input={inputLevel} Key={`${key}Level`} onChange={onChangeInputs} />
                  </div>
                )}
              </div>
            )
          })}

          <div className="shadow1 bg-white rounded p-5 md:col-span-3 sm:col-span-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mb-3 gap-3">
            <div className={`input mb-3 md:col-span-3 sm:col-span-2`}>
              <RenderInput
                input={{
                  ...inputs.emailSection,
                  inputType: 'Section',
                  hasMark: false,
                  title: `4. ${inputs.emailSection.title}`,
                }}
                Key={`emailSection`}
                onChange={onChangeInputs}
              />
            </div>
            <div className={`input mb-3`}>
              <RenderInput input={inputs.emailSubject} Key="emailSubject" onChange={onChangeInputs} />
            </div>
            <div className={`input mb-3 col-span-full`}>
              <RenderInput input={inputs.emailComment} Key={`emailComment`} onChange={onChangeInputs} />
            </div>
          </div>

          <div className="input mb-3">
            <RenderInput
              input={
                {
                  ...inputs.file,
                  multiple: isNewDoc,
                } as InputType
              }
              Key="file"
              onChange={onChangeInputs}
            />
          </div>
          {!isNewDoc && (
            <div className="input mb-3 md:col-span-2 p-2">
              <label className="block font-variation-settings-600 mb-2">Placeholders</label>
              <span className="text-sm">{state.doc.placeholders.join(', ')}</span>
            </div>
          )}
          {isNewDoc && renderFileNameInputs()}
        </div>
        {warning && (
          <div className="mb-3 p-3 bg-yellow-100 text-yellow-800" dangerouslySetInnerHTML={{ __html: warning }} />
        )}
        <div className="flex items-center justify-center">
          <Button onClick={() => onOk(false)}>{isNewDoc ? 'Submit' : 'Update'}</Button>
          {!isNewDoc && (
            <Button color="white" onClick={() => onBack()}>
              Cancel
            </Button>
          )}
        </div>

        {unknownPlaceholders && unknownPlaceholders.length && (
          <UnknownPlaceholderModal
            placeholders={unknownPlaceholders}
            onOk={() => {
              setUnknownPlaceholders(null)
              onOk(true)
            }}
            onCancel={() => setUnknownPlaceholders(null)}
          />
        )}
      </div>
    </div>
  )
}
