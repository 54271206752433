import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { getStateBankDepartments } from 'services'
import svgLoading from 'stories/assets/loading.svg'

import { NewDialog } from './NewDialog'

export interface StateBankDepartment {
  id: number
  state: string
  text: string
}

export const StateBankDepartments = () => {
  const [isLoading, setLoading] = useState(false)
  const [values, setValues] = useState<StateBankDepartment[]>([])
  const [selectedItem, setSelectedItem] = useState<StateBankDepartment | null>(null)

  useEffect(() => {
    setLoading(true)
    getStateBankDepartments().then((values: StateBankDepartment[]) => {
      setValues(values)
      setLoading(false)
    })
  }, [])

  const onUpdate = (item: StateBankDepartment) => {
    setSelectedItem(item)
  }

  const onItemSubmit = (text: string) => {
    if (!selectedItem) return

    const newValues = cloneDeep(values)
    const index = values.findIndex((v) => v.id == selectedItem.id)
    newValues[index].text = text
    setValues(newValues)
    setSelectedItem(null)
  }

  return (
    <div className="Prepayment-Type-Container">
      <div className="flex flex-wrap justify-between">
        <h1 className="text-2xl font-variation-settings-600 flex items-center pb-5">
          State Bank Department
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h1>
      </div>

      <div className="relative overflow-auto shadow-md sm:rounded-lg">
        <LayoutLoading show={isLoading} />
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-3 w-[40px]">
                No
              </th>
              <th scope="col" className="px-2 py-3">
                State
              </th>
              <th scope="col" className="px-2 py-3">
                Description
              </th>
              <th scope="col" className="px-2 py-3 w-[70px]">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-[14px] text-gray-900">
            {values
              .sort((a, b) => (a.state > b.state ? 1 : -1))
              .map((value, index) => {
                return (
                  <tr key={`${value.state}-${index}`} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      {index + 1}
                    </td>

                    <td className="px-2 py-2">{value.state}</td>

                    <td className="px-2 py-2">
                      <p className="capitalize mb-2">{value.text}</p>
                    </td>

                    <td className="px-2 py-2">
                      <span className="flex">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => onUpdate(value)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>
                      </span>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        {selectedItem && (
          <NewDialog
            item={selectedItem}
            onSubmit={onItemSubmit}
            onClose={() => {
              setSelectedItem(null)
            }}
          />
        )}
      </div>
    </div>
  )
}
