import { KeyIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { ChangePassword } from 'components/Modals'
import { itemCountPerPage } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { deleteAccount, getAccounts } from 'services'
import { svgSearch } from 'stories/assets'
import { Button, Input, Pagination } from 'stories/components'
import { confirm, formatDate } from 'utils'
import { useTitle } from 'utils/pageTitle'

import { NewDialog } from './NewDialog'

export interface Account {
  id: number
  name: string
  email: string
  userType: string
  createdAt: string
}

export const ManageAccounts = () => {
  const [isLoading, setLoading] = useState(false)
  const [isShowAdd, setShowAdd] = useState(false)
  const [isOpenChangePwd, setIsOpenChangePwd] = useState(false)
  const [total, setTotal] = useState(0)
  const [pageNum, setPageNum] = useState(0)
  const [filterQuery, setFilterQuery] = useState('')
  const [values, setValues] = useState<Account[]>([])
  const [selectedItem, setSelectedItem] = useState<Account | null>(null)
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'createdAt',
    orderDir: '-1',
  })
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  useTitle(`Manage Accounts`)

  useEffect(() => {
    if (isLoading) return
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [pageNum])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && filterData(filters, 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const onChangeFilter = (key: string, value: any) => {
    if (!isGetUsersOnce) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else {
      filterData(newFilters, 0)
      setPageNum(0)
    }
  }

  const filterData = async (filters: any, _pageNum: number = -1) => {
    if (isLoading) return
    if (_pageNum === -1) _pageNum = pageNum
    const filterData = {
      ...filters,
      skip: pageNum * itemCountPerPage,
      count: itemCountPerPage,
    }

    setLoading(true)
    const { data, total } = await getAccounts(filterData)
    setValues(data)
    setTotal(total)
    setLoading(false)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onAdd = () => {
    setShowAdd(true)
    setSelectedItem(null)
  }

  const onUpdate = (item: Account) => {
    setShowAdd(true)
    setSelectedItem(item)
  }

  const onItemSubmit = (item: Account) => {
    const newValues = cloneDeep(values)
    if (!selectedItem) newValues.push(item)
    else {
      const index = values.findIndex((v) => v.id == item.id)
      newValues[index] = item
    }
    setValues(newValues)
    setShowAdd(false)
    filterData(filters)
  }

  const onUpdatePwd = async (item: Account) => {
    setIsOpenChangePwd(true)
    setSelectedItem(item)
  }

  const onCloseChangePwd = () => {
    setIsOpenChangePwd(false)
    setSelectedItem(null)
  }

  const onDelete = async (item: Account) => {
    if (item.id) {
      const result = await confirm('Do you want to remove this user?')
      if (!result) return
    }

    const index = values.findIndex((v) => v.createdAt === item.createdAt)
    if (index === -1) return

    setLoading(true)
    if (item.id) await deleteAccount(item.id)

    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
    setLoading(false)

    filterData(filters)
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <div className="flex flex-wrap justify-between">
          <h1 className="text-2xl font-variation-settings-600 flex items-center mb-3">
            <span>Manage Account</span>
          </h1>
          <Button onClick={onAdd}>Create User</Button>
        </div>

        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
          <Input
            type="search"
            title="Search ..."
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
        </div>

        <div className="relative overflow-auto shadow-md sm:rounded-lg">
          <LayoutLoading show={isLoading} />
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-2 py-3 w-[40px]">
                  No
                </th>
                <th scope="col" className="px-2 py-3">
                  Full Name
                </th>
                <th scope="col" className="px-2 py-3">
                  Email
                </th>
                <th scope="col" className="px-2 py-3">
                  User Type
                </th>
                <th scope="col" className="px-2 py-3 w-[100px]">
                  Created At
                </th>
                <th scope="col" className="px-2 py-3 w-[70px]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {values.map((value, index) => {
                return (
                  <tr key={`${value.createdAt}-${index}`} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      {index + 1 + pageNum * itemCountPerPage}
                    </td>

                    <td className="px-2 py-2">{value.name}</td>

                    <td className="px-2 py-2">{value.email}</td>

                    <td className="px-2 py-2">{value.userType}</td>

                    <td className="px-2 py-2">{formatDate(value.createdAt)}</td>

                    <td className="px-2 py-2">
                      <span className="flex">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => onUpdate(value)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>

                        <span
                          className="text-blue-600 p-1 hover-shadow1 cursor-pointer"
                          onClick={() => onUpdatePwd(value)}
                        >
                          <KeyIcon className="w-4 h-4"></KeyIcon>
                        </span>

                        <span className="text-red-700 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(value)}>
                          <TrashIcon className="w-4 h-4"></TrashIcon>
                        </span>
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {isShowAdd && <NewDialog item={selectedItem} onSubmit={onItemSubmit} onClose={() => setShowAdd(false)} />}
          {isOpenChangePwd && selectedItem && (
            <ChangePassword userId={selectedItem.id} email={selectedItem.email} onClose={onCloseChangePwd} />
          )}
        </div>

        <div className="flex justify-end items-center mt-3">
          <div className="flex-1" />
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={pageNum}
          />
        </div>
      </div>
    </div>
  )
}
