export * from './api.status.constants'
export * from './api.url.constants'
export * from './input.type.constants'
export * from './modal.types'

export const itemCountPerPage = 10
export const pullCreditReportLimitDays = 30
export const TimeZone = 'America/New_York'

export const daysOfWeek: Record<number, string> = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}
