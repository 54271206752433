import {
  API_DOCS_DOWNLOAD,
  API_DOCUMENT,
  API_DOCUMENT_ITEM,
  API_DOCUMENT_ITEM_PROGRESS,
  API_DOCUMENT_NAMES,
  API_DOCUMENT_PATCH,
} from 'config'
import Api from 'services/api'

export const downloadS3Documents = (key: string) => {
  return Api.post(API_DOCS_DOWNLOAD, { key })
}

export const openS3Document = async (fileKey: string, useViewer = false) => {
  const res = await downloadS3Documents(fileKey)
  var windowReference: any = window.open()
  if (useViewer)
    windowReference.location = `https://docs.google.com/gview?url=${encodeURIComponent(
      res.url,
    )}&embedded=true&pid=explorer&efh=false&a=v&chrome=false`
  else windowReference.location = res.url
}

export const createNewDocument = (data: Record<string, any>, files: File[]) => {
  return Api.uploadFiles(API_DOCUMENT, data, {}, files)
}

export const updateDocument = (id: number, data: Record<string, any>, files: File[]) => {
  return Api.uploadFiles(API_DOCUMENT_ITEM, data, { id }, files)
}

export const filterPipeline = (filters: Record<string, any>) => {
  return Api.get(API_DOCUMENT, filters)
}

export const getDocument = (id: number) => {
  return Api.get(API_DOCUMENT_ITEM, {}, { id })
}

export const getDocumentNames = () => {
  return Api.get(API_DOCUMENT_NAMES)
}

export const patchDocument = (data: Record<string, any>) => {
  return Api.put(
    API_DOCUMENT_PATCH,
    data,
    {},
    {
      responseType: 'blob',
      timeout: 15000,
    },
  )
}

export const deleteDocument = (id: number) => {
  return Api.delete(API_DOCUMENT_ITEM, {}, { id })
}

export const deleteDocuments = (ids: number[]) => {
  return Api.delete(API_DOCUMENT, { ids })
}

export const updateDocumentOrder = (id: number, order: number) => {
  return Api.put(API_DOCUMENT_ITEM, { order }, { id })
}

export const updateDocumentStatus = (id: number, status: boolean) => {
  return Api.put(API_DOCUMENT_ITEM, { status }, { id })
}

export const updateDocumentNotes = (id: number, notes: string) => {
  return Api.put(API_DOCUMENT_ITEM, { notes }, { id })
}

export const updateDocumentProgress = (id: number, progress: string) => {
  return Api.put(API_DOCUMENT_ITEM_PROGRESS, { progress }, { id })
}
