import { API_PREPAYMENT_TYPE, API_PREPAYMENT_TYPE_ITEM } from 'config'
import Api from 'services/api'

export const getPrepaymentTypes = () => {
  return Api.get(API_PREPAYMENT_TYPE)
}

export const createPrepaymentType = (data: Record<string, any>) => {
  return Api.post(API_PREPAYMENT_TYPE, data)
}

export const updatePrepaymentType = (id: number, data: Record<string, any>) => {
  return Api.put(API_PREPAYMENT_TYPE_ITEM, data, { id })
}

export const deletePrepaymentType = (id: number) => {
  return Api.delete(API_PREPAYMENT_TYPE_ITEM, {}, { id })
}
