import { API_STATE_BANK_DEPARTMENT, API_STATE_BANK_DEPARTMENT_ITEM } from 'config'
import Api from 'services/api'

export const getStateBankDepartments = () => {
  return Api.get(API_STATE_BANK_DEPARTMENT)
}

export const updateStateBankDepartment = (id: number, data: Record<string, any>) => {
  return Api.put(API_STATE_BANK_DEPARTMENT_ITEM, data, { id })
}
