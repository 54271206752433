export const baseUrl = process.env.REACT_APP_API_URL

export const API_USER_SIGN_IN = '/auth/login'
export const API_USER_FORGET_PASSWORD = '/auth/forgetPassword'
export const API_USER_CHECK_PASSPORT = '/auth/checkPassport/:selector/:token'
export const API_USER_RESET_PASSWORD = '/auth/resetPassword/:selector/:token'
export const API_USER_ACCOUNT_EXECUTIVES = '/auth/getAccountExecutives'
export const API_USER_REGISTER = '/auth/register'

export const API_USER_PROFILE = '/user/profile'

export const API_DOCS_DOWNLOAD = '/storage/download'
export const API_DOCS_MULTI_DOWNLOAD = '/storage/multiDownload'
export const API_DOCS_UPLOAD = '/storage/upload'

export const API_HOME_NEWSLETTER = '/home/newsletter'
export const API_HOME_SUBMIT_CONTACTUS = '/home/submitContactUs'

export const API_DOCUMENT = '/document'
export const API_DOCUMENT_PATCH = '/document'
export const API_DOCUMENT_ITEM = '/document/:id'
export const API_DOCUMENT_ITEM_PROGRESS = '/document/progress/:id'
export const API_DOCUMENT_BY_PLACEHOLDER = '/document/byPlaceholder'
export const API_DOCUMENT_NAMES = '/document/names'

export const API_PLACEHOLDER = '/admin/placeholder'
export const API_PLACEHOLDER_ITEM = '/admin/placeholder/:id'

export const API_PREPAYMENT_TYPE = '/admin/prepaymentType'
export const API_PREPAYMENT_TYPE_ITEM = '/admin/prepaymentType/:id'

export const API_STATE_GUARANTY = '/admin/stateGuaranty'
export const API_STATE_GUARANTY_ITEM = '/admin/stateGuaranty/:id'

export const API_STATE_VERBIAGE = '/admin/stateVerbiage'
export const API_STATE_VERBIAGE_ITEM = '/admin/stateVerbiage/:id'

export const API_STATE_VERBIAGE_NOTE = '/admin/stateVerbiageNote/:type'
export const API_STATE_VERBIAGE_NOTE_ITEM = '/admin/stateVerbiageNote/:id'

export const API_STATE_BANK_DEPARTMENT = '/admin/stateBankDepartment'
export const API_STATE_BANK_DEPARTMENT_ITEM = '/admin/stateBankDepartment/:id'

export const API_ACCOUNT = '/admin/account'
export const API_ACCOUNT_ITEM = '/admin/account/:id'

export const API_CONSTRUCTION_DEED = '/admin/constructionDeedOfTrust/:type'
export const API_CONSTRUCTION_DEED_ITEM = '/admin/constructionDeedOfTrust/:type/:id'

export const API_DOCSIGN_GET_PACKAGES = '/docsign/v1/packages'

export const API_DOCSIGN_GET_DOCUMENTS = '/docsign/v1/packages/:packageId/documents'
export const API_DOCSIGN_GET_PARTICIPANTS = '/docsign/v1/packages/:packageId/participants'
