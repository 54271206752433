import { ClockIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { useEffect, useRef, useState } from 'react'
import type { Color } from 'stories/types'

import { GoogleAutoComplete } from '../GoogleAutoComplete/GoogleAutoComplete'

function validateEmail(email: string) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

interface AutoCompleteProps {
  /**
   * What background color to use
   */
  color?: Color
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Is autofocus
   */
  autoFocus?: boolean
  /**
   * Type of Input
   */
  type?: 'email' | 'text' | 'number'
  /**
   * Title of Input
   */
  title?: string
  /**
   * Placeholder of Input
   */
  placeholder?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Value of Input
   */
  value?: any

  toLowerCase?: boolean
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string
  /**
   * autoCompleteType
   */
  autoCompleteType?: string
  /**
   * Is has icon
   */
  hasIcon?: boolean
  /**
   * Required
   */
  required?: boolean

  splitByComma?: boolean

  additionalComponent?: (v: any) => JSX.Element

  onChange: (e: any) => void // Array<string> => void

  onBlur?: () => void

  history?: boolean

  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const AutoComplete = ({
  color = 'sky',
  type = 'text',
  title = '',
  placeholder = ' ',
  disabled = false,
  name = '',
  value = [],
  toLowerCase = false,
  error = '',
  className = '',
  required = false,
  splitByComma = false,
  autoCompleteType = '',
  additionalComponent = undefined,
  onChange = () => {},
  onBlur = () => {},
  history = false,
  showHistory = () => {},
  ...props
}: AutoCompleteProps) => {
  const [values, setValues] = useState<Array<string | number>>(cloneDeep(value))
  const [isEditing, setEditing] = useState(false)
  const [text, setText] = useState('')
  const [textError, setTextError] = useState('')

  useEffect(() => {
    if (!Array.isArray(value)) {
      setValues([])
    }
    if (JSON.stringify(value) == JSON.stringify(values)) return
  }, [value])

  const onRemove = (v: string | number) => {
    if (disabled) return
    let newValues = cloneDeep(values)
    const index = newValues.indexOf(v)
    newValues.splice(index, 1)
    onChange(newValues)
    setValues(cloneDeep(newValues))
    setTimeout(() => {
      divRef?.current?.click()
    }, 250)
  }

  const onAdd = () => {
    if (disabled) return
    setEditing(true)
  }

  const onTextUpdate = (t: string) => {
    setText(t)
    setTextError('')
  }

  const onConfirm = async () => {
    const newValues = cloneDeep(values)
    let texts = [text]
    if (splitByComma) texts = text.split(',')
    let hasError = false

    texts.map((text) => {
      let v: string | number = text.trim()
      if (toLowerCase) v = v.toLowerCase()
      if (!v) return
      if (type == 'number') v = parseInt(v)
      if (newValues.includes(v)) {
        setTextError('Already exists.')
        hasError = true
        return
      }
      if (type == 'email' && !validateEmail(v as string)) {
        setTextError('Email address is not in a valid format.')
        hasError = true
        return
      }
      if (type == 'number' && isNaN(v as number)) {
        setTextError('Value is not in a valid number format.')
        hasError = true
        return
      }
      newValues.push(v)
    })
    if (hasError) return

    try {
      await onChange(newValues)
      setText('')
      setEditing(false)
      setValues(newValues)
      setTimeout(() => {
        divRef?.current?.click()
      }, 250)
    } catch (e) {}
  }

  const onCancel = () => {
    setText('')
    setTextError('')
    setEditing(false)
  }

  const classNames = [
    'block',
    'rounded',
    'py-1.5',
    'px-2',
    'w-full',
    'text-sm',
    'text-gray-900',
    'bg-transparent',
    'border',
    'border-gray-300',
    'appearance-none',
    'focus:outline-none',
    'focus:ring-0',
    `focus:border-${color}-600`,
    'peer',
    error && 'border-rose-700',
    className,
    disabled && 'bg-gray-100',
  ].join(' ')

  const divRef = useRef<HTMLDivElement>(null)

  return (
    <div className="input-container mb-3" {...props}>
      <div className={`group relative z-0 w-full text-left ${classNames}`}>
        <div className="group relative flex items-center">
          <label
            htmlFor={name}
            className="text-[12px] text-gray-700 top-1.5 border-b z-10 origin-[0] left-2.5 gap-2 items-center"
          >
            {title}
            {required && '*'}
          </label>
          {history && (
            <span className="ml-3 hidden group-hover:inline" onClick={() => showHistory()}>
              <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
        </div>
        <div className="flex flex-wrap my-2 gap-2" ref={divRef} onClick={onBlur}>
          {Array.isArray(values) &&
            values.map((v) => (
              <div className="flex items-center border border-gray-300 p-2 rounded" key={v}>
                <TrashIcon
                  className="w-4 h-4 cursor-pointer text-gray-500 hover:text-red-600 mr-1"
                  onClick={() => onRemove(v)}
                />
                {additionalComponent ? additionalComponent(v) : null}
                <label>{v}</label>
              </div>
            ))}
          <div
            className="flex border border-gray-300 p-2 mr-3 hover:bg-shade-blue hover:border-white cursor-pointer rounded text-gray-500 hover:text-white"
            onClick={onAdd}
          >
            <PlusIcon className="w-5 h-5" />
          </div>
        </div>

        {isEditing && (
          <div className="flex w-full">
            {autoCompleteType === 'map' ? (
              <GoogleAutoComplete
                title={'Address'}
                key={`${Date.now()}-autoComplete-GoogleAddress`}
                inputKey={`${Date.now()}-autoComplete-GoogleAddress`}
                className="w-full"
                error={error}
                value={text as string}
                onChange={(value) => onTextUpdate(value)}
              />
            ) : (
              <input
                type={type}
                className="block p-2.5 w-full z-20 text-sm text-gray-900 rounded-l border border-gray-300 focus:border-shade-blue focus:outline-none focus:ring-0 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                placeholder={placeholder}
                required
                autoFocus
                value={text}
                onChange={(e) => onTextUpdate(e.target.value)}
              />
            )}
            <button
              type="button"
              className="p-2.5 text-sm font-medium text-white bg-shade-blue hover:bg-sky-600 cursor-pointer focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 focus:z-5"
              onClick={onConfirm}
              disabled={!text.trim()}
            >
              Confirm
            </button>
            <button
              type="button"
              className="p-2.5 text-sm font-medium text-white bg-gray-400 rounded-r-lg cursor-pointer hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-blue-800 focus:z-5"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        )}
        {textError && <p className="peer-invalid:visible text-rose-700 text-[13px] pt-[1px]">{textError}</p>}
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-[13px] pt-[1px] pl-1">{error}</p>}
    </div>
  )
}
