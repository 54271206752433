import { ArrowDownTrayIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { openS3Document } from 'services'
import { getDocuments, getPariticpants } from 'services/apis/docsign'
import { formatTime } from 'utils'

import type { IPackage } from './index'

export interface IParticipant {
  id: number
  participantId: string
  packageId: string
  email: string
  name: string
  role: string
  viewDate: number
  startDate: number
  consentDate: number
  action: string
  longName: string
  longSign: string
  initialName: string
  initialSign: string
  createdAt: string
  updatedAt: string
}

export interface IDocument {
  id: number
  documentId: string
  packageId: string
  filename: string
  name: string
  description: string
  width: number
  height: number
  fileKey: string
  pageCount: number
  resolutionDPI: number
  marks: IMark[]
  createdAt: string
  updatedAt: string
}

export interface IMark {
  x: number
  y: number
  page: number
  ipAddr: string
  markId: string
  isSigned: boolean
  markType: string
  signedAt: number
  participantId: string
}

export const DocSignPacakgeDetail = () => {
  const [isLoading, setLoading] = useState(false)
  const { state } = useLocation() as { state: { package: IPackage } }
  const navigate = useNavigate()

  const [documents, setDocuments] = useState<IDocument[]>([])
  const [participants, setParticipants] = useState<IParticipant[]>([])

  useEffect(() => {
    setLoading(true)
    console.log(state)
    const getPackageDetailInfo = async () => {
      if (state) {
        console.log('package', state.package)
        const documents = await getDocuments(state.package.packageId)
        console.log(documents)
        setDocuments(documents)
        const participants = await getPariticpants(state.package.packageId)
        setParticipants(participants)
        setLoading(false)
        console.log(participants)
      }
    }
    getPackageDetailInfo()
    console.log(participants)
  }, [])

  const onBack = () => {
    navigate('/sign')
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <div className="text-shade-blue flex items-center mb-2 font-variation-settings-600">
          <ChevronLeftIcon className="w-4 h-4" />
          <span className="ml-1 cursor-pointer hover:underline" onClick={onBack}>
            Return to Packages
          </span>
        </div>
        <LayoutLoading show={isLoading} />
        <div className="grid grid-cols-2">
          <div className="col-span-1">
            <div className="flex items-center gap-4 p-2">
              <div>
                <p className="mb-1">Name:</p>
                <p className="mb-1">Package ID:</p>
                <p className="mb-1">Loan Number:</p>
              </div>
              <div>
                <p className="mb-1 font-semibold">
                  {state.package.documentNames.length ? state.package.documentNames.join(' / ') : state.package.name}
                </p>
                <p className="mb-1 font-semibold">{state.package.packageId}</p>
                <p className="mb-1 font-semibold">{state.package.loanNumber}</p>
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex items-center gap-4 p-2">
              <div>
                <p className="mb-1">Work Sheet ID:</p>
                <p className="mb-1">Account:</p>
                <p className="mb-1">Status:</p>
              </div>
              <div>
                <p className="mb-1 font-semibold">{state.package.worksheetId}</p>
                <p className="mb-1 font-semibold">{state.package.account}</p>
                <p className="mb-1 font-semibold">{state.package.status}</p>
              </div>
            </div>
          </div>
        </div>
        {state.package.finalFileKey && (
          <div
            className=" flex items-center font-variation-settings-600 text-shade-blue hover:underline cursor-pointer px-2"
            onClick={() => openS3Document(state.package.finalFileKey, false)}
          >
            <ArrowDownTrayIcon className="w-4 h-4 mr-1" />
            <span>Download</span>
          </div>
        )}

        <div className="flex flex-wrap items-center gap-2 mb-3 mt-12">
          <h1 className="text-2xl font-variation-settings-600">
            <span>Documents</span>
          </h1>
          <div className="flex-1" />
        </div>

        <div className="table-container relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-2 py-3">No</th>
                <th className="px-2 py-3">Name</th>
                <th className="px-2 py-3">Document Id</th>
                <th className="px-2 py-3">Description</th>
                <th className="px-2 py-3">Page Count</th>
                <th className="px-2 py-3">Updated At</th>
                <th className="px-2 py-3">Action</th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {documents?.map((item: IDocument, index: number) => {
                return (
                  <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      <span className="flex-col flex">{index + 1}</span>
                    </td>
                    <td className="px-2 font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      <span>{`${item.name}`}</span>
                    </td>
                    <td className="px-2 py-2">
                      <span>{item.documentId}</span>
                    </td>
                    <td className="px-2 py-2">
                      <span>{item.description}</span>
                    </td>
                    <td className="px-2 py-2">{item.pageCount}</td>

                    <td className="px-2 py-2">
                      <span>{`${formatTime(item.updatedAt)}`}</span>
                    </td>
                    <td className="px-2 py-2">
                      <span className="flex gap-1">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => openS3Document(item.fileKey, false)}
                        >
                          <ArrowDownTrayIcon className="w-4 h-4" />
                        </span>
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="flex flex-wrap items-center gap-2 mb-3 mt-12">
          <h1 className="text-2xl font-variation-settings-600">
            <span>Participants</span>
          </h1>
          <div className="flex-1" />
        </div>

        <div className="table-container relative overflow-x-auto shadow-md sm:rounded-lg mb-4">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-2 py-3">No</th>
                <th className="px-2 py-3">Name</th>
                <th className="px-2 py-3">Participant Id</th>
                <th className="px-2 py-3">Email</th>
                <th className="px-2 py-3">Role</th>
                <th className="px-2 py-3">View Date</th>
                <th className="px-2 py-3">Start Date</th>
                <th className="px-2 py-3">Consent Date</th>
                <th className="px-2 py-3">Updated At</th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {participants?.map((item: IParticipant, index: number) => {
                return (
                  <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                    <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      <span className="flex-col flex">{index + 1}</span>
                    </td>
                    <td className="px-2 font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                      <span>{item.name}</span>
                    </td>
                    <td className="px-2 py-2">
                      <span>{item.participantId}</span>
                    </td>
                    <td className="px-2 py-2">
                      <span>{item.email}</span>
                    </td>
                    <td className="px-2 py-2">{item.role}</td>
                    <td className="px-2 py-2">{formatTime(item.viewDate)}</td>
                    <td className="px-2 py-2">{formatTime(item.startDate)}</td>
                    <td className="px-2 py-2">{formatTime(item.consentDate)}</td>

                    <td className="px-2 py-2">
                      <span>{formatTime(item.updatedAt)}</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
