import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import type { InputType, ModalProps } from 'config'
import { fullStates } from 'config/states.constants'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { updateStateVerbiage } from 'services'
import { Modal } from 'stories/components'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { StateVerbiage } from '.'

const defaultInput = {
  title: 'Verbiage',
  inputType: 'textarea',
  error: '',
  required: true,
}

export const NewDialog = ({ item, ...props }: ModalProps & { item: StateVerbiage }) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<InputType[]>([])

  useEffect(() => {
    const newInputs: InputType[] = (item.text.length == 0 ? [''] : item.text).map(
      (text) =>
        ({
          ...defaultInput,
          value: text,
        } as InputType),
    )
    setInputs(newInputs)
  }, [])

  const onChangeInputs = (index: string, value: string) => {
    const key = Number(index)
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onNewNote = () => {
    const newInputs = cloneDeep(inputs)
    newInputs.push({
      ...defaultInput,
      title: `Verbiage ${newInputs.length + 1}`,
    } as InputType)
    setInputs(newInputs)
  }

  const onDelete = (index: number) => {
    const newInputs = cloneDeep(inputs)
    newInputs.splice(index, 1)
    setInputs(newInputs)
  }

  const onOk = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const inputData: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (value !== undefined) inputData[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)

    try {
      await updateStateVerbiage(item.id, { text: Object.values(inputData) })

      props.onSubmit && props.onSubmit(Object.values(inputData))
    } catch (e) {}
    setLoading(false)
  }

  return (
    <Modal
      isOpen
      title="Update State Verbiage"
      titleOkay="Ok"
      onOk={onOk}
      onClose={() => props.onClose()}
      loading={isLoading}
    >
      <div className={`text-gray-600 text-md w-144`}>
        <p className="font-variation-settings-600 text-lg mb-3">
          {fullStates[item.state]} ({item.state})
        </p>
        {inputs.map((input, index) => {
          input.title = `Verbiage ${index + 1}`
          return (
            <div key={`${inputs.length}-${index}`} className="flex gap-2 input mb-3">
              <div className="flex-1">
                <RenderInput input={input} Key={`${index}`} onChange={onChangeInputs} />
              </div>

              <span className="flex mt-2 w-6 h-6">
                {inputs.length != 1 && (
                  <span className="text-red-700 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(index)}>
                    <TrashIcon className="w-4 h-4"></TrashIcon>
                  </span>
                )}
              </span>
            </div>
          )
        })}

        <span className="flex">
          <span
            className="flex items-center gap-1 text-shade-blue px-3 py-2 rounded hover-shadow1 cursor-pointer"
            onClick={onNewNote}
          >
            <PlusIcon className="w-4 h-4"></PlusIcon> Add
          </span>
        </span>
      </div>
    </Modal>
  )
}
