import { logout, signInRequest } from 'actions'
import jwt from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Input2 } from 'stories/components'
import { delay } from 'utils'
import { useTitle } from 'utils/pageTitle'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  email: '',
  password: '',
}

function _Login(props: any) {
  useTitle(`Login`)

  const [inputStates, setInputStates] = useState(defaultInputs)
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  })

  const { auth } = props
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.email) newErrors.email = 'Required'
    if (!inputStates.password) newErrors.password = 'Required'
    setErrors(newErrors)

    if (!inputStates.email || !inputStates.password) return
    await queryClient.clear()
    await props.signInRequest(inputStates, async () => {
      await delay(0.5)
      navigate('/pipeline')
    })
  }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)
  }

  const keyPress = (e: any) => {
    if (e.charCode === 13) {
      onSubmit()
    }
  }

  useEffect(() => {
    let success = false

    try {
      const payload: any = jwt(auth.token)
      if (payload.exp * 1000 > Date.now()) {
        success = true
      } else {
        toast(`Your token is expired!`, { type: 'error' })
      }
    } catch {}
    if (!success) {
      props.logout()
    }
  }, [])

  return (
    <div className="login-container sm:text-center lg:text-left w-full block mt-0 relative bg-stone-100">
      <div className="max-w-screen-2xl m-auto lg:pt-10 md:pt-5 sm:pt-2 pb-10">
        {!auth.isAuthenticated && (
          <div className="bg-white rounded lg:max-w-xl max-w-full pl-6 pr-6 pt-8 pb-6 relative mx-auto">
            <p className="text-base text-stone-800 text-4xl font-variation-settings-600 mb-3">Login</p>

            <div className="mb-5 grid grid-cols-3 gap-4">
              <div className="h-1 bg-shade-blue w-full" />
              <div className="h-1 bg-stone-200 w-full" />
              <div className="h-1 bg-stone-200 w-full" />
            </div>

            <form>
              <Input2
                type="email"
                title="Email"
                value={inputStates.email}
                required
                error={errors.email ? 'Please provide a valid email address.' : ''}
                onChange={(value) => onChange('email', value)}
              />
              <div className="mb-5"></div>
              <Input2
                type="password"
                title="Password"
                value={inputStates.password}
                required
                error={errors.password ? 'Please provide a valid password.' : ''}
                onChange={(value) => onChange('password', value)}
                onKeyPress={(e) => keyPress(e)}
              />

              <div className="my-7"></div>

              <Button className="mt-1" color="sky" full bold onClick={onSubmit} loading={auth.fetchingToken}>
                Sign In
              </Button>

              {/* <Link to="/forgetPassword">
                <p className="block mt-3 hover:text-gray-900 hover:underline text-gray-500">
                  Forgot/Reset your password?
                </p>
              </Link> */}
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    auth: state.auth,
  }
}

export const Login = connect(mapStateToProps, { logout, signInRequest })(_Login)
