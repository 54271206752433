import type { InputType } from 'config'
import { states } from 'config/states.constants'
import { ConstructionOptions, EntityOptions, FilterOptions } from 'pages/Pipeline/constant'

export const documentOptions = ['state', 'entity', 'construction']

export const defaultInputs = (): Record<string, InputType> => {
  return {
    name: {
      title: 'File name',
      inputType: 'text',
      error: '',
      required: true,
    },

    stateSection: {
      inputType: 'section',
      title: 'State',
      span: 3,
    },
    state: {
      title: 'State Options',
      inputType: 'multiselectpanel',
      options: states,
      error: '',
      span: 3,
      gridClassName: 'grid-cols-3 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12',
    },
    stateLevel: {
      title: 'State Filter Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    entitySection: {
      inputType: 'section',
      title: 'Entity',
      span: 3,
    },
    entity: {
      title: 'Entity Options',
      inputType: 'multiselectpanel',
      options: EntityOptions,
      error: '',
      span: 3,
    },
    entityLevel: {
      title: 'Entity Filter Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    constructionSection: {
      inputType: 'section',
      title: 'Construction',
      span: 3,
    },
    construction: {
      title: 'Construction Options',
      inputType: 'multiselectpanel',
      options: ConstructionOptions,
      error: '',
      span: 3,
    },
    constructionLevel: {
      title: 'Construction Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    emailSection: {
      inputType: 'section',
      title: 'Email',
      span: 3,
    },
    emailSubject: {
      title: 'Email Subject',
      inputType: 'text',
      error: '',
      required: true,
    },
    emailComment: {
      title: 'Email Comment',
      inputType: 'textarea',
      error: '',
      required: true,
    },

    fileSection: {
      inputType: 'section',
      title: 'Template file',
      span: 3,
    },
    file: {
      title: 'Choose file',
      inputType: 'file',
      error: '',
      acceptFileTypes: '.doc,.docx',
      required: true,
      multiple: true,
      span: 3,
    },
  }
}
