import type { InputType, ModalProps } from 'config'
import { fullStates } from 'config/states.constants'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { updateStateBankDepartment } from 'services'
import { Modal } from 'stories/components'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { StateBankDepartment } from '.'

const defaultInput: InputType = {
  title: 'Description',
  inputType: 'textarea',
  error: '',
  required: true,
}

export const NewDialog = ({ item, ...props }: ModalProps & { item: StateBankDepartment }) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<InputType>(defaultInput)

  useEffect(() => {
    const newInputs: InputType = {
      ...defaultInput,
      value: item.text,
    } as InputType
    setInputs(newInputs)
  }, [])

  const onChangeInputs = (index: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs.value = value
    setInputs(newInputs)
  }

  const onOk = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const { value } = newStats

    let error = InputValidate(newStats)
    newStats.error = error
    if (error.length > 0) hasError = true

    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)

    try {
      await updateStateBankDepartment(item.id, { text: value })

      props.onSubmit && props.onSubmit(value)
    } catch (e) {}
    setLoading(false)
  }

  return (
    <Modal
      isOpen
      title="Update State Bank Department"
      titleOkay="Ok"
      onOk={onOk}
      onClose={() => props.onClose()}
      loading={isLoading}
    >
      <div className={`text-gray-600 text-md w-144`}>
        <p className="font-variation-settings-600 text-lg mb-3">
          {fullStates[item.state]} ({item.state})
        </p>
        <div className="flex gap-2 input mb-3">
          <div className="flex-1">
            <RenderInput input={inputs} Key="__" onChange={onChangeInputs} />
          </div>
        </div>
      </div>
    </Modal>
  )
}
