import { API_ACCOUNT, API_ACCOUNT_ITEM } from 'config'
import Api from 'services/api'

export const getAccounts = (filterData: Record<string, any>) => {
  return Api.get(API_ACCOUNT, filterData)
}

export const createAccount = (data: Record<string, any>) => {
  return Api.post(API_ACCOUNT, data)
}

export const updateAccount = (id: number, data: Record<string, any>) => {
  return Api.put(API_ACCOUNT_ITEM, data, { id })
}

export const deleteAccount = (id: number) => {
  return Api.delete(API_ACCOUNT_ITEM, {}, { id })
}

export const updateAccountPassword = (id: number, password: string) => {
  return Api.post(API_ACCOUNT_ITEM, { password }, { id })
}
