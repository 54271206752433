import { API_DOCUMENT_BY_PLACEHOLDER, API_PLACEHOLDER, API_PLACEHOLDER_ITEM } from 'config'
import Api from 'services/api'

export const getPlaceholders = () => {
  return Api.get(API_PLACEHOLDER)
}

export const getPlaceholderValues = () => {
  return Api.get(API_PLACEHOLDER_ITEM, {}, { id: 'values' })
}

export const createPlaceholder = (value: string, description: string) => {
  return Api.post(API_PLACEHOLDER, { value, description })
}

export const updatePlaceholder = (id: number, value: string, description: string) => {
  return Api.put(API_PLACEHOLDER_ITEM, { value, description }, { id })
}

export const deletePlaceholder = (id: number) => {
  return Api.delete(API_PLACEHOLDER_ITEM, {}, { id })
}

export const getRelatedDocuments = (value: string) => {
  return Api.get(API_DOCUMENT_BY_PLACEHOLDER, { value })
}
