import type { InputType } from 'config'
import { states } from 'config/states.constants'

export const LoanPurposeOptions = ['Purchase', 'Cash Out Refinance', 'No Cash Out Refinance']

export const OccupancyOptions = ['Investment', 'Primary/Secondary']

export const EntityOptions = ['Entity', 'Non-Entity']

export const PrepayOptions = ['Prepay', 'No prepay']

export const AmortizationTypeOptions = ['ARM', 'Fixed', 'Interest Only', 'Fully Amortized']

export const ConstructionOptions = ['Construction', 'Non Construction']

export const InterestCalculationOptions = ['Dutch', 'Non Dutch']

export const FilterOptions = { 0: 'Optional', 1: 'Mandatary' }

export const BorrowersOptions = { 1: 'Borrower1', 2: 'Borrower2', 3: 'Borrower3', 4: 'Borrower4' }

export const documentOptions = [
  'state',
  'loanPurpose',
  'occupancy',
  'entity',
  'prepay',
  'amortizationType',
  'construction',
  'interestCalculation',
  'borrowers',
]

export const defaultInputs = (): Record<string, InputType> => {
  return {
    name: {
      title: 'File name',
      inputType: 'text',
      error: '',
      required: true,
    },

    stateSection: {
      inputType: 'section',
      title: 'State',
      span: 3,
    },
    state: {
      title: 'State Options',
      inputType: 'multiselectpanel',
      options: states,
      error: '',
      span: 3,
      gridClassName: 'grid-cols-3 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12',
    },
    stateLevel: {
      title: 'State Filter Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    loanPurposeSection: {
      inputType: 'section',
      title: 'Loan Purpose',
      span: 3,
    },
    loanPurpose: {
      title: 'Loan Purpose Options',
      inputType: 'multiselectpanel',
      options: LoanPurposeOptions,
      error: '',
      span: 3,
    },
    loanPurposeLevel: {
      title: 'Loan Purpose Filter Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    occupancySection: {
      inputType: 'section',
      title: 'Occupancy',
      span: 3,
    },
    occupancy: {
      title: 'Occupancy Options',
      inputType: 'multiselectpanel',
      options: OccupancyOptions,
      error: '',
      span: 3,
    },
    occupancyLevel: {
      title: 'Occupancy Filter Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    entitySection: {
      inputType: 'section',
      title: 'Entity',
      span: 3,
    },
    entity: {
      title: 'Entity Options',
      inputType: 'multiselectpanel',
      options: EntityOptions,
      error: '',
      span: 3,
    },
    entityLevel: {
      title: 'Entity Filter Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    prepaySection: {
      inputType: 'section',
      title: 'Prepay',
      span: 3,
    },
    prepay: {
      title: 'Prepay Options',
      inputType: 'multiselectpanel',
      options: PrepayOptions,
      error: '',
      span: 3,
    },
    prepayLevel: {
      title: 'Prepay Filter Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    amortizationTypeSection: {
      inputType: 'section',
      title: 'Amotization Type',
      span: 3,
    },
    amortizationType: {
      title: 'Amotization Type Options',
      inputType: 'multiselectpanel',
      options: AmortizationTypeOptions,
      error: '',
      span: 3,
    },
    amortizationTypeLevel: {
      title: 'Amotization Type Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    constructionSection: {
      inputType: 'section',
      title: 'Construction',
      span: 3,
    },
    construction: {
      title: 'Construction Options',
      inputType: 'multiselectpanel',
      options: ConstructionOptions,
      error: '',
      span: 3,
    },
    constructionLevel: {
      title: 'Construction Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    interestCalculationSection: {
      inputType: 'section',
      title: 'Interest Calculation',
      span: 3,
    },
    interestCalculation: {
      title: 'Interest Calculation Options',
      inputType: 'multiselectpanel',
      options: InterestCalculationOptions,
      error: '',
      span: 3,
    },
    interestCalculationLevel: {
      title: 'Interest Calculation Level',
      inputType: 'select',
      options: FilterOptions,
      error: '',
      value: 1,
      required: true,
    },

    borrowersSection: {
      inputType: 'section',
      title: 'Borrowers',
      span: 3,
    },
    borrowers: {
      title: 'Borrowers Options',
      inputType: 'multiselectpanel',
      options: BorrowersOptions,
      error: '',
      span: 3,
    },

    fileSection: {
      inputType: 'section',
      title: 'Template file',
      span: 3,
    },
    file: {
      title: 'Choose file',
      inputType: 'file',
      error: '',
      acceptFileTypes: '.doc,.docx',
      required: true,
      multiple: true,
      span: 3,
    },
  }
}
