import { useEffect, useState } from 'react'
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm'
import { AlertModal } from 'stories/components'

const AlertDialog = ({ show, proceed, confirmation, options }: ReactConfirmProps | any) => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  useEffect(() => {
    setLastUpdatedAt(Date.now())
  }, [show])

  return (
    <AlertModal
      isOpen={show}
      onOk={() => proceed(true as any)}
      lastUpdatedAt={lastUpdatedAt}
      content={confirmation}
      options={options}
    />
  )
}

const alertDlg = confirmable(AlertDialog)

const createAlert = createConfirmation(alertDlg)

export const alert = (content: string | JSX.Element, options: Record<string, any> = {}) =>
  createAlert({ confirmation: content, options })
